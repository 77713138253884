import axiosInstance from '../../api/AxiosInstance';
import { MessageTemplate } from '../../api/templates/types';
import * as typ from './templatesTypes';


export const deleteMessageTemplate = 
  async (requestOption: { companyId: number; templateId: number; }) => 
    await axiosInstance.delete(`companies/${requestOption.companyId}/messaging/templates/${requestOption.templateId}`);

export const patchMessageTemplate =
  async (requestOptions: typ.PatchMessageTemplateRequestOptions): Promise<MessageTemplate> =>
  await axiosInstance.patch(`companies/${requestOptions.companyId}/messaging/templates/${requestOptions.templateId}`, {
    name: requestOptions.name,
    text: requestOptions.text,
    file_url: requestOptions.fileUrl,
  });

export const patchInteractiveTemplate = async (
  requestOptions: typ.CreateInteractiveTemplateRequestData,
  templateId: number
): Promise<MessageTemplate> =>
  await axiosInstance.patch(`companies/${requestOptions.company_id}/messaging/templates/${templateId}`, {
    name: requestOptions.name,
    waba_interactive: requestOptions.waba_interactive,
  });

export const postMessageTemplate =
  async (requestOptions: typ.CreateMessageTemplateRequestOptions): Promise<MessageTemplate> =>
  await axiosInstance.post(`companies/${requestOptions.companyId}/messaging/templates/`, {
    name: requestOptions.name,
    text: requestOptions.text,
    company_id: requestOptions.companyId,
    type: requestOptions.type,
    file_url: requestOptions.fileUrl,
  });

export const postInteractiveTemplate = async (
  requestOptions: typ.CreateInteractiveTemplateRequestData
): Promise<MessageTemplate> => 
  await axiosInstance.post(`companies/${requestOptions.company_id}/messaging/templates/`, requestOptions);

export const getListWabaTemplates = async (
  requestOptions: { companyId: number; connectionId: number; }
): Promise<typ.CreateWabaTemplateResponse> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/messaging/templates/waba_templates/`, {
    params: { connection_id: requestOptions.connectionId }
  });

export const postWabaTemplate = async (
  requestOptions: typ.CreateWabaTemplateRequest
): Promise<typ.WabaTemplateInfo> => {
  const { company_id: companyId, ...rest } = requestOptions;
  return await axiosInstance.post(`companies/${companyId}/messaging/templates/waba_templates/`, { ...rest });
};

export const postDeleteWabaTemplate = async (requestOptions: typ.DeleteWabaTemplateRequest): Promise<void> =>{
  const { company_id: companyId, ...rest } = requestOptions;
  await axiosInstance.post(`companies/${companyId}/messaging/templates/waba_templates/actions/delete`, { ...rest });
};

export const uploadWabaTemplateFile = async (
  requestOptions: { companyId: number; file: File; }): Promise<{ url: string }> => {
  const formData = new FormData();
  formData.append('file', requestOptions.file);
  return await axiosInstance.post(`companies/${requestOptions.companyId}/files/waba_templates/`, formData);
};

export const patchWabaTemplate = async (
  requestOptions: typ.UpdateWabaTemplateRequest
): Promise<typ.WabaTemplateInfo> => {
  const { company_id: companyId, ...rest } = requestOptions;
  return await axiosInstance.patch(`/companies/${companyId}/messaging/templates/waba_templates/actions/update`,
    { ...rest });
};
