import { useContext } from 'react';
import './ChatTemplateWabaBody.scss';
import { WabaTemplateBody } from '../../../../../../../../api/templates/types';
import { chatTemplatesFillContext } from "../../../../ChatTemplatesFillProvider/ChatTemplatesFillProvider";
import { replaceWabaParams } from '../../../../utils';


type Props = {
  body: WabaTemplateBody;
};

const ChatTemplateWabaBody = ({ body }: Props) => {
  const { bodyParams } = useContext(chatTemplatesFillContext);

  return (
    <div className="chatTemplateWabaBody">
      <p className="smallText">{replaceWabaParams(bodyParams, body)}</p>
    </div>
  );
};

export default ChatTemplateWabaBody;
