import { useTranslation } from "react-i18next";
import { isTodayBetween } from "./getDateTime/getDateTime";
import blackFridayRu from '../assets/blackFriday24-ru.png';
import blackFridayEn from '../assets/blackFriday24-en.png';
import blackFridayEs from '../assets/blackFriday24-es.png';


const eventStart = '2024-11-29T00:01:00';
const eventEnd = '2024-12-06T23:59:00';

type eventDetails = {
  active: boolean;
  discount: boolean;
  link: string;
  banner: string;
};

type PromotionType = {
  image: string;
  link: string;
};

const promotionDetails: Record<string, PromotionType> = {
  ru: { image: blackFridayRu, link: 'https://radist.online/blackfriday/2024'},
  en: { image: blackFridayEn, link: 'https://radist.online/en/blackfriday' },
  es: { image: blackFridayEs, link: 'https://radist.online/es/blackfriday' },
};

export function useEvent(): eventDetails {
  const { i18n } = useTranslation();

  return {
    active: isTodayBetween(eventStart, eventEnd),
    banner: promotionDetails[i18n.language].image,
    discount: false,
    link: promotionDetails[i18n.language].link
  };
}
