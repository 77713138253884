import React, { useMemo, useState } from 'react';
import './StatisticPreview.scss';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { IStatistic } from '../../../BroadcastsTypes';
import { formatNumberLocale } from '../../../../../utils/utils';
import { getRecipientsCount } from '../../../BroadcastsAPI';
import { useBroadcastItem } from '../../../api';
import { calcPercentage } from '../../../utils';


type Props = {
  statistic: string;
  arrStatistics: IStatistic;
};

const StatisticPreview = ({ statistic, arrStatistics }: Props) => {
  const { t, i18n } = useTranslation();
  const url = useRouteMatch<{ companyId: string; broadcastId: string }>('/companies/:companyId/broadcasts/:broadcastId');
  const broadcastItem = useBroadcastItem();
  const [recipientsCount, setRecipientsCount] = useState<number>();

  useMemo(async () => {
    if (broadcastItem) {
      const response = await getRecipientsCount({
        companyId: Number(url?.params.companyId),
        connectionId: broadcastItem.connectionId,
        from_active_chat: broadcastItem.params.fromActiveChat,
        conditions: broadcastItem.params.conditions
      });
      setRecipientsCount(response.estimatedRecipientsCount);
    }
  }, []);

  const statisticNum: Record<string, number> = {
    recipients: broadcastItem?.status === 'PENDING' ? recipientsCount! : arrStatistics.total,
    delivered: arrStatistics.delivered,
    read: arrStatistics.read,
  };

  const percentage = calcPercentage(arrStatistics.total, statisticNum[statistic]);

  return (
    <div className="statisticPreview">
      <div>
        <h6>{t(`broadcasts.params.${statistic}`)}</h6>
        <div className={`statisticPreview__number ${statistic}`}>{statisticNum[statistic]}</div>
      </div>
      {statistic !== 'recipients' && percentage !== undefined &&
      <div className="statisticPreview__percent">{formatNumberLocale(percentage, 1, i18n.language)}%</div>}
    </div>
  );
};

export default StatisticPreview;
