import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as PauseIcon } from "../../../../../assets/pause-icon.svg";
import { ReactComponent as PlayIcon } from "../../../../../assets/play.svg";
import Video from "../../../../Chat/Chat/ChatField/WabaTemplateComponent/Video";

type Props = {
  attachedFile?: File,
  attachedFileUrl?: string,
}

const VideoView = ({ attachedFile, attachedFileUrl }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlayed, setIsPlayed] = useState(false);
  const [pauseIconIsVisible, setPauseIconIsVisible] = useState(false);

  useEffect(() => {
    if (isPlayed && videoRef.current) {
      videoRef.current.play();
    }
  }, [isPlayed]);

  const onClickVideo = () => {
    setIsPlayed(!isPlayed);
  }

  const onClickPause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }

  return (
    <div className='videoPreview'>
      <Video videoRef={videoRef} attachedFile={attachedFile} attachedFileUrl={attachedFileUrl} />
      <div className="video-controls"
        onClick={onClickVideo} >
        {isPlayed ? (
          <div>
            <PauseIcon className={`video-controls__pauseIcon ${pauseIconIsVisible && 'active'}`}
              onClick={onClickPause}
              onMouseOver={() => setPauseIconIsVisible(true)}
              onMouseOut={() => setPauseIconIsVisible(false)}
            />
          </div>
        ) : (
          <PlayIcon className='video-controls__playIcon' />
        )}
      </div>
    </div>
  );
};

export default VideoView;
