import { ReactElement } from "react";
import { ReactComponent as WhatsAppBusinessLogo } from "../../../../assets/logo-whatsapp-buiness.svg";
import { ReactComponent as WhatsAppLogo } from "../../../../assets/logo-whatsapp.svg";
import { ReactComponent as TelegramLogo } from "../../../../assets/logo-telegram.svg";
import { ResGetAllConnForChatType } from "../../../../api/CompanyAPI";


export type ConnectionPopup = {
  id: number;
  name: string;
  type: string;
};

export const chatsIconsPopupTypes: Record<string, ReactElement> = {
  waba: <WhatsAppBusinessLogo />,
  whatsapp: <WhatsAppLogo />,
  telegram: <TelegramLogo />
};

export const getConnectionsPopup = (allConnectionsForChat: ResGetAllConnForChatType[]) => {
  const conList: ConnectionPopup[] = [];
  if (allConnectionsForChat) {
    const typesList = Object.keys(chatsIconsPopupTypes);
    typesList.forEach((type) => {
      const list = allConnectionsForChat.filter((connection) => connection.type === type);
      list.map(item => conList.push({
        id: item.connectionId,
        name: item.name,
        type: item.type,
      }));
    });
  }
  return conList;
};
