import {
  SubscriptionInvoiceDialog, SubscriptionInvoiceItem, checkItemDialog, checkItemSubscription, invoiceItemTypes
} from '../../SubscriptionsTypes';
import { formatNumberLocale } from '../../../../../utils/utils';
import UserSubscription from '../../models/UserSubscription';
import Gift from './Gift';


export type SubscriptionInvoiceItemType = {
  type: string;
  itemType: string;
  serviceType: string;
  volume?: number;
  amount?: string;
  discount?: string;
  total?: string;
};

export function InvoiceItems(
  subsInvoice: (SubscriptionInvoiceItem | SubscriptionInvoiceDialog)[],
  subsSelected?: UserSubscription[], lang: string = 'ru') {
  return subsInvoice.map((item) => {
    const subscription = subsSelected?.find((sub) => (
      checkItemSubscription(item) && sub.id === item.subscriptionId));
    if (checkItemSubscription(item) && subscription) {
      return {
        type: subscription.type, serviceType: subscription.serviceType,
        itemType: invoiceItemTypes[item.type],
        volume: item.products.length,
        amount: formatNumberLocale(AmountItemPay(item), 2, lang),
        discount: formatNumberLocale(DiscountItemPay(item), 2, lang),
        total: formatNumberLocale(AmountPay(item), 2, lang),
      };
    }
    if (checkItemDialog(item)) {
      return { type: 'waba_dialog', itemType: invoiceItemTypes[item.type],
        serviceType: 'dialog', volume: item.count, amount: formatNumberLocale(item.price, 4, lang),
        total: formatNumberLocale(item.price * item.count, 2, lang) };
    }
    return { type: '', itemType: '', serviceType: '' };
  });
}

const AmountItemPay = (item: SubscriptionInvoiceItem) =>
  item.products.reduce((product, elem) => product + elem.amount, 0);

const DiscountItemPay = (item: SubscriptionInvoiceItem) =>
  item.products.reduce((product, elem) => product + elem.discount, 0);

const AmountPay = (item: SubscriptionInvoiceItem) =>
  (item.products.reduce((product, elem) => product + elem.amount, 0)
    - item.products.reduce((product, elem) => product + elem.discount, 0));

type MonthPeriodDiscont = { month: number, comment: string; discount: string; component?: JSX.Element; };


const monthPeriods: MonthPeriodDiscont[] = [
  { month: 1, comment: 'subscriptions.invoice.period.month', discount: '' },
  { month: 3, comment: 'subscriptions.invoice.period.months_a', discount: '-10%' },
  { month: 6, comment: 'subscriptions.invoice.period.months_b', discount: '-15%' },
  { month: 12, comment: 'subscriptions.invoice.period.months_b', discount: '-20%' }
];

const gifts: Record<string, JSX.Element> = {
  1: <>{null}</>,
  3: <>{null}</>,
  6: <Gift giftValue={30} />,
  12: <Gift giftValue={60} />
};

export const METHODS_PAYMENT = ['CARD_RU', 'CARD_OTHER', 'BANK_TRANSFER'] as const;
export type MethodsPaymentType = typeof METHODS_PAYMENT[number];

export const getMonthPeriods = (eventOn: boolean) => {

  if (eventOn) {
    return monthPeriods.map(value => ({...value, component: gifts[value.month]}));
  }

  return  monthPeriods;
};
