import './ChatTemplateWabaFooter.scss';
import { WabaTemplateFooter } from '../../../../../../../../api/templates/types';


type Props = {
  footer: WabaTemplateFooter;
};

const ChatTemplateWabaFooter = ({ footer }: Props) => {

  if (!footer.text) {
    return <></>;
  }

  return (
    <div className="chatTemplateWabaFooter">
      <p className="smallText">{footer.text}</p>
    </div>
  );
};

export default ChatTemplateWabaFooter;
