import React, {useEffect, useRef, useState} from 'react';
import './CircleVideo.scss';
import play from '../../assets/play-button.svg';
import { ReactComponent as Pointer } from '../../assets/pointer-slider.svg';
import { convertAudioTime } from '../../views/Chat/Chat/MessageTypes/helpers';
import CircularSlider from '@fseehawer/react-circular-slider';
import Loader from "../Loader/Loader";


type Props = {
  video: string;
  setIsPreviewVideo?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CircleVideo = ({ video, setIsPreviewVideo }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlay, setIsPlay] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [durationVideo, setDurationVideo] = useState<number>(Number(videoRef.current?.duration));

  useEffect(() => {
    if (!videoRef.current) return;
    if (isPlay) {
      videoRef.current.play();
    } else videoRef.current.pause();
  }, [isPlay]);

  useEffect(() => {
    if (!videoRef.current) return;
    if (setIsPreviewVideo) setIsPlay(true);
    videoRef.current.onloadedmetadata = () => {
      if (!videoRef.current) return;
      setDurationVideo(videoRef.current.duration);
    };
  }, [setIsPreviewVideo]);

  const onTimeUpdate = (value: number) => {
    setCurrentTime(value);
  };

  const onDragging = () => {
    setIsDragging(!isDragging);
    setIsPlay(!isPlay);
  };

  const onPlaying = (
    e: React.MouseEvent<HTMLVideoElement, MouseEvent> | React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (setIsPreviewVideo) setIsPreviewVideo(true);
    setIsPlay(!isPlay);
  };

  const onEnded = () => {
    if (videoRef.current) {
      if (setIsPreviewVideo) setIsPreviewVideo(true);
      setIsPlay(false);
      videoRef.current.currentTime = 0;
    }
  };

  const onChangeSlider = (value: number) => {
    if (videoRef.current && isDragging) {
      videoRef.current.currentTime = value;
    }
  };

  if (!videoRef && !durationVideo) {
    return <Loader />
  }

  return (
    <div className='circleVideo'>
      <div className="videoContainer">
        <div className="videoContainer__content">
          <video
            src={video} ref={videoRef}
            onTimeUpdate={() => onTimeUpdate(Number(videoRef.current?.currentTime))}
            onClick={(e) => onPlaying(e)} onEnded={onEnded} />
          <div className='circularSlider'>
            <CircularSlider
              min={0} max={durationVideo} width={360} dataIndex={currentTime}
              isDragging={onDragging}
              trackColor="#E0E0E0" trackSize={4} trackDraggable={true}
              progressColorFrom="#FE6D04" progressColorTo="#FE6D04"
              progressSize={4} onChange={onChangeSlider}
              knobSize={16} knobColor="#FE6D04">
              <Pointer y="0" x="0" width={17} height={17} />
            </CircularSlider>
          </div>
          <span className="duration">{convertAudioTime(currentTime)}</span>
        </div>
        <div className="video-controls">{!isPlay && <img alt="play" src={play} onClick={(e) => onPlaying(e)} />}</div>
      </div>
    </div>
  );
};

export default CircleVideo;
