import './ChatTemplateFillHeader.scss';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { configChatTemplateWabaFile } from '../../../ChatTemplateWaba/components/utils';
import { addAlertWithCustomText } from '../../../../../../../../components/Alert/alertSlice';
import { WabaTemplateHeaderFormat } from '../../../../../../../../api/templates/types';
import { ReactComponent as AttachmentIcon } from '../../../../../../../../assets/attach.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../../assets/trash.svg';
import Button from '../../../../../../../../components/Button';
import ChatWabaTemplateFileType from "../ChatWabaTemplateFileType";


type Props = {
  format: WabaTemplateHeaderFormat;
  file: File | undefined;
  setFile: (file: undefined | File) => void;
  isError: boolean;
};

const ChatTemplateFillHeader = ({ format, file, setFile, isError }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const maxSize = configChatTemplateWabaFile[format].maxSize;

  const onLoadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (maxSize && e.target.files && e.target.files[0].size > maxSize) {
      dispatch(addAlertWithCustomText({ message: t('chats.templates.fill.error_big_file'), type: 'alarm' }));
    } else if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <div className={`chatTemplateFillHeader ${isError && !file ? 'error' : ''}`}>
      {file ? (
        <>
          <ChatWabaTemplateFileType type={format} attachmentFile={file} />
          <button id="delete" onClick={() => setFile(undefined)}>
            <DeleteIcon />
          </button>
        </>
      ) : (
        <>
          <div className="chatTemplateFillHeader__button">
            <Button type='button' color="white" textType="small" text={t(configChatTemplateWabaFile[format].title)}
              image={<AttachmentIcon />} />
            <input type="file" accept={configChatTemplateWabaFile[format].accept} onChange={(e) => onLoadFile(e)} />
          </div>
          <p className="smallText">
            {t(configChatTemplateWabaFile[format].description, { size: configChatTemplateWabaFile[format].sizeMB })}
          </p>
        </>
      )}
    </div>
  );
};

export default ChatTemplateFillHeader;
