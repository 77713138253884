import { useContext, useEffect } from 'react';
import './WabaTemplateBasic.scss';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { wabaTemplateCategories, WabaTemplateCategory } from '../../../../templatesTypes';
import { WabaTemplateKind, wabaTemplateKinds } from '../../../api';
import { WabaTemplateBasicData } from '../../../WabaTemplateProvider/utils';
import { wabaTemplateContext } from '../../../WabaTemplateProvider/WabaTemplateProvider';
import { locales } from '../../locales';
import Dropdown from '../../../../../Settings/Dropdown/Dropdown';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';


const categoriesAvailaible: Record<WabaTemplateKind, WabaTemplateCategory[]> = {
  auth: ['AUTHENTICATION'],
  carousel: ['MARKETING'],
  message: ['MARKETING', 'UTILITY']
};

function WabaTemplateBasic() {
  const { t, i18n } = useTranslation();
  const { wabaTemplateState, updateWabaTemplateState } = useContext(wabaTemplateContext);

  const schema = yup.object().shape({
    kind: yup.string().oneOf([...wabaTemplateKinds]),
    custom_name: yup.string().required(t('error.form.empty_field')).max(256, t('error.form.max_length', { limit: 256 })),
    category: yup.string().required(),
    template_locale: yup.string().required(t('error.form.empty_field')),
    allow_category_change: yup.boolean().nullable(true).transform((_, val) => (val !== "" ? val : null)),
  });

  const methods = useForm<WabaTemplateBasicData>({ mode: 'all', resolver: yupResolver(schema) });

  useEffect(() => {
    if (wabaTemplateState) {
      methods.reset({
        kind: wabaTemplateState.kind,
        custom_name: wabaTemplateState.custom_name,
        category: wabaTemplateState.category,
        allow_category_change: wabaTemplateState.allow_category_change,
        template_locale: wabaTemplateState.custom_name === ''
          ? locales.find((locale) => locale.id === i18n.language)?.id
          : wabaTemplateState.template_locale
      }, { keepDefaultValues: wabaTemplateState.template_locale !== '' && wabaTemplateState.custom_name !== '' });
    }
  }, []);

  const getCategories= () => {
    let categories: string[];
    if (!kind) {
      categories = wabaTemplateCategories;
    } else {
      categories = categoriesAvailaible[kind];
    }
    return (categories.map((category) => ({ id: category, name: t(`templates.waba_list.categories.${category}`) })));
  };

  const kinds = wabaTemplateKinds.map((kind) => ({ id: kind, name: t(`templates.waba_list.form.kinds.${kind}`) }));

  const answers = ['yes', 'no'].map((answer) => ({ id: answer, name: t(answer), value: answer === 'yes' }));

  const kind = useWatch({ name: 'kind', control: methods.control, defaultValue: wabaTemplateState.kind });

  useEffect(() => {
    let newCategory: WabaTemplateCategory | undefined;
    if (kind === 'auth') newCategory = 'AUTHENTICATION';
    
    if (kind === 'carousel' || kind === 'message') newCategory = 'MARKETING';

    if (kind !== 'message') methods.setValue('allow_category_change', undefined);
  
    if (newCategory) methods.setValue('category', newCategory);
  }, [kind]);

  const onSubmit = (payload: WabaTemplateBasicData) => {
    updateWabaTemplateState({ type: 'basic', payload });
  };

  return (<>
    <FormProvider {...methods}>
      <Input name='custom_name' label={t('templates.waba_list.form.name')} />
      <Dropdown name='kind' label={t('templates.waba_list.form.kind')}
        items={kinds}  labelKey='name' valueKey='id' />
      <Dropdown name='category' label={t('templates.waba_list.form.category')}
        items={getCategories()} labelKey='name' valueKey='id' disabled={kind === 'auth' || kind === 'carousel'}/>
      <Dropdown name='template_locale' label={t('templates.waba_list.form.locale')}
        items={locales} labelKey='name' valueKey='id' placeholder={t('select')} />
      {kind === 'message' && <Dropdown name='allow_category_change' label={t('templates.waba_list.form.allow_category_change')}
        items={answers} labelKey='name' valueKey='value' />}
      <Button type='submit' text={t('next')} color='orange' textType='regular' onClick={methods.handleSubmit(onSubmit)}
        disabled={!methods.formState.isDirty} />
    </FormProvider>
  </>);
}

export default WabaTemplateBasic;
