import { RootState } from '../../store/store';


export const currentChatSelector = (state: RootState) => state.chats.currentChat;
export const searchedChatsSelector = (state: RootState) => state.chats.searchedChats;
export const searchedChatsValueSelector = (state: RootState) => state.chats.searchedValueChats;
export const applicationIsBlurredSelector = (state: RootState) => state.chats.isBlurred;
export const notificationDataSelector = (state: RootState) => state.chats.notificationData;
export const chatsWithContactsSelector = (state: RootState) => state.chats.chatsWithContacts;
export const chatsError = (state: RootState) => state.chats.errors;
export const chatsLoaders = (state: RootState) => state.chats.loaders;
export const searchedChatsWithContacts = (state: RootState) => state.chats.searchedChatsWithContacts;
export const DialogsCountsSelector = (state: RootState) => state.chats.countsDialogs;
export const chatMessageTemplatesSelector = (state: RootState) => state.chats.messageTemplates;
export const chatWabaTemplatesSelector = (state: RootState) => state.chats.wabaTemplates;
export const chatInteractiveTemplatesSelector = (state: RootState) => state.chats.interactiveTemplates;
