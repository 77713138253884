import React, { BaseSyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import './ChatField.scss';
import 'emoji-mart/css/emoji-mart.css';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { BaseEmoji } from 'emoji-mart';
import { useTranslation } from 'react-i18next';
import { validateAttachmentFileType } from './helpers';
import { currentChatSelector } from '../../chatsSelector';
import { useAppDispatch } from '../../../../store/store';
import { addUploadFileMessage, markChatIsAnswered, sendMessage } from '../../chatsSlice';
import { MessageTemplate } from '../../../../api/templates/types';
import { useHasPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { useMobile } from "../../../../components/utils/hooks";
import { chatContext } from "../ChatContent/StickyViewport/Context";
import { useChatStatus } from '../../api/status';
import { ReactComponent as AttachmentIcon } from '../../../../assets/attach.svg';
import { ReactComponent as TemplatesIcon } from '../../../../assets/template.svg';
import { ReactComponent as CrossedEyeIcon } from '../../../../assets/crossed-eye.svg'
import VoiceRecorder from './VoiceRecorder';
import AttachmentPreview from './AttachmentPreview';
import CustomTemplateAttachmentPreview from '../AttachmentsPreviews/CustomTemplateAttachmentPreview';
import Timer from './Timer/Timer';
import EmojiComponent from "../../../../components/EmojiComponent/EmojiComponent";
import Button from "../../../../components/Button";
import TemplateSelector from './TemplateSelector';


const ChatField = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompanyId = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const pickerTriggerButtonRef = useRef<SVGSVGElement>(null);
  const currentChat = useSelector(currentChatSelector);
  const [text, setText] = useState('');
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState<MessageTemplate>();
  const [picker, setPicker] = useState(false);
  const [attachedFile, setAttachedFile] = useState<File>();
  const [voiceFile, setVoiceFile] = useState<File | null>(null);
  const [isRecorded, setIsRecorded] = useState(false);
  const hasPermissionSubscriptions = useHasPermission('subscriptionsView');
  const isMobile = useMobile();
  const isRecordedMobile = isRecorded && isMobile;
  const { chatContentRef } = useContext(chatContext);
  const { availability, chatStatus, setWabaTimerElapsed } = useChatStatus();

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const dispatchMessageSendEvent = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setText('');

    if (text.length > 0 && !chatStatus?.blocked && currentCompanyId?.params.companyId) {
      dispatch(
        sendMessage({
          messageType: 'text',
          caption: text,
          url: '',
          companyId: Number(currentCompanyId?.params.companyId),
        })
      ).then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          if (chatContentRef && chatContentRef.current) {
            chatContentRef.current.scrollTo(0, chatContentRef.current.scrollHeight)
          }
        }
      });
    }
  };

  const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    dispatchMessageSendEvent(e);
  };

  const onEnterForm = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey) return;

    if (e.key === 'Enter') {
      dispatchMessageSendEvent(e);
    }
  };

  const onConfirmSendMessage = (caption: string) => {
    if (attachedFile && inputFileRef.current) {
      const type = attachedFile.type.split('/')[0];
      validateAttachmentFileType(attachedFile.type);
      dispatch(addUploadFileMessage({
        messageType: 'fileLoading',
        fileUploaded: URL.createObjectURL(attachedFile),
        fileType: validateAttachmentFileType(attachedFile.type) ? type : 'file',
        fileName: attachedFile.name,
        caption,
      }));
      inputFileRef.current.value = '';
    }
  };

  const onAttachmentFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setText('');
      setSelectedMessageTemplate(undefined);
      setAttachedFile(e.target.files[0]);
    }
  };

  const onSendVoiceFile = () => {
    if (voiceFile) {
      const audioURL = URL.createObjectURL(voiceFile);
      dispatch(addUploadFileMessage({
        messageType: 'fileLoading',
        fileUploaded: audioURL,
        fileType: 'voice',
        fileName: voiceFile.name,
      }));
    }
  };

  const onMarkChatAnswered = () => {
    const { connectionId, chatId } = currentChat;
    if (currentCompanyId?.params.companyId) {
      dispatch(markChatIsAnswered({
        connectionId,
        chatId,
        companyId: Number(currentCompanyId?.params.companyId),
      }));
    }
  };

  const handlerEndTime = (timeIsOver:boolean) => setWabaTimerElapsed(timeIsOver);

  const onSelectEmoji = (emoji: BaseEmoji) => {
    if (textAreaRef.current) textAreaRef.current.focus();
    setText(`${text}${emoji.native}`);
  };

  const onClickEmoji = () => {
    if (!chatStatus?.blocked) setPicker(!picker);
  };

  useEffect(() => {
    if (voiceFile) {
      onSendVoiceFile();
    }

    return () => setVoiceFile(null);
  }, [voiceFile]);

  if (!chatStatus) {
    return (
      <div className='chatField'>
        <div className='chatField__container'>
          <div className='chatFieldBlockedText' />
          <div className='buttonsContainer' />
        </div>
      </div>
    );
   }

  return (
    <div className='chatField'>
      {availability?.waba &&
        <Timer date={availability.waba.expiresAt} handlerEndTime={handlerEndTime} />}
      <form className='chatField__container' onSubmit={onSubmitForm}>
        {chatStatus.blocked
          ? (
            <div className='chatFieldBlockedText'>
              <p className='smallText'>
                {chatStatus.message && t(chatStatus.message)}
                {chatStatus.waba?.shouldPay &&  <>
                  {hasPermissionSubscriptions
                    ? <Link to={`${currentCompanyId?.url}/settings/subscriptions`}>
                        {t('settings.settings_list.subscriptions')}
                      </Link>
                    : t('settings.settings_list.subscriptions')
                  }
                </>}
                {chatStatus.waba?.wabaExpired && <span><TemplatesIcon /> WhatsApp.</span>}
              </p>
            </div>
          ) : (
            <>
              <TextareaAutosize placeholder={t('chats.chat.message')} ref={textAreaRef}
                minRows={4} maxRows={4} onKeyDown={onEnterForm} value={text}
                onChange={onChangeHandler} disabled={chatStatus.blocked} />
              {selectedMessageTemplate && (
                <div className='chatField__container__customTemplateAttachmentPreview'>
                  <CustomTemplateAttachmentPreview
                    setText={setText}
                    customTemplate={selectedMessageTemplate}
                    setSelectedCustomTemplate={setSelectedMessageTemplate}
                  />
                </div>
              )}
            </>
        )}
        <div className='buttonsContainer'>
          {!isRecordedMobile && <div className='buttonsContainer__leftBlock'>
            <button className={`buttonsContainer__leftBlock_answerButton ${currentChat.unansweredCount < 1 && 'blocked'}`}
              type='button' onClick={currentChat.unansweredCount <= 0 ? undefined : onMarkChatAnswered}>
              <CrossedEyeIcon />
              <p className='smallText'>{t('no_reply')}</p>
            </button>
          </div>}
          <div className='buttonsContainer__rightBlock'>
            <div className='iconsContainer'>
              <VoiceRecorder isRecorded={isRecorded} setIsRecorded={setIsRecorded} setVoiceFile={setVoiceFile}
                  textAreaIsBlocked={chatStatus.blocked} />
              {!isMobile &&
                <EmojiComponent picker={picker} setPicker={setPicker} onSelect={onSelectEmoji}
                  pickerRef={pickerTriggerButtonRef} isBlocked={chatStatus.blocked} onClick={onClickEmoji} />}
              <input ref={inputFileRef} type='file' id='file-attachment' onChange={onAttachmentFile}
                onKeyDown={(event) => event.preventDefault()} disabled={chatStatus.blocked}/>
              {!isRecordedMobile &&
                <label htmlFor='file-attachment' style={{ height: '20px' }}>
                  <AttachmentIcon className={`attachIcon ${chatStatus.blocked ? 'blocked' : ''}`} />
                </label>}
            </div>
            {!isRecordedMobile && (
              <TemplateSelector blocked={chatStatus.blocked && !chatStatus.waba?.wabaExpired}
                wabaDialogOnly={chatStatus.waba?.wabaExpired ?? false}
                setMessageTemplate={setSelectedMessageTemplate} />
            )}
            <Button color='orange' textType='small' disabled={chatStatus.blocked} type='submit' text={t('send')} />
          </div>
        </div>
        {attachedFile && (
          <AttachmentPreview
            attachmentFile={attachedFile}
            inputFileRef={inputFileRef} captionFromTextarea={text}
            onConfirmSendMessage={onConfirmSendMessage}
            setText={setText} setAttachedFile={setAttachedFile}
          />
        )}
      </form>
    </div>
  );
};

export default ChatField;
