import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { allConnectionsForChatSelector } from '../../companiesSelector';
import { currentChatSelector } from '../../chatsSelector';
import { WabaTemplateBody, WabaTemplateHeader } from '../../../../api/templates/types';
import { ParamsType } from './components/ChatTemplateFill/utils';
import { chatTemplatesContext } from './ChatTemplatesProvider/ChatTemplatesProvider';
import { chatTemplatesFillContext } from './ChatTemplatesFillProvider/ChatTemplatesFillProvider';
import ChatTemplateSelected from './components/ChatTemplateSelector/ChatTemplateSelector';
import ChatTemplateFill from './components/ChatTemplateFill';
import ChatTemplatePreview from './components/ChatTemplatePreview';


const chatTemplateMobileSteps = ['selected', 'preview', 'fill', 'finish'] as const;

export type ChatTemplateMobileSteps = typeof chatTemplateMobileSteps[number];

export const useConfigForChatTemplate = () => {
  const { wabaTemplate, customTemplate } = useContext(chatTemplatesContext);
  const { fillTemplate } = useContext(chatTemplatesFillContext);
  const components: React.JSX.Element[] = [];

  if (!fillTemplate && (!customTemplate || !wabaTemplate)) {
    components.push(<ChatTemplateSelected key="selected" />);
  }
  if (fillTemplate) {
    components.push(<ChatTemplateFill key="fill" />);
  }
  if (customTemplate || wabaTemplate) {
    components.push(<ChatTemplatePreview key="preview" />);
  }

  return components;
};

export const configForChatTemplateMobile: Record<ChatTemplateMobileSteps, React.JSX.Element> = {
  selected: <ChatTemplateSelected key="selected" />,
  preview: <ChatTemplatePreview key="preview" />,
  fill: <ChatTemplateFill key="fill" />,
  finish: <ChatTemplatePreview key="preview" />,
};

export const getTemplatesSwitch = () => {
  const { t } = useTranslation();

  return [
    { tabId: 'waba', tabName: t('chats.templates.switch.waba-templates') },
    { tabId: 'my', tabName: t('chats.templates.switch.my') },
    { tabId: 'interactive', tabName: t('chats.templates.switch.interactive') },
  ];
};

type ParamsTemplateType = {
  id: number;
  value: string;
};

export const useFetchTemplates = () => {
  const currentChat = useSelector(currentChatSelector);
  const connections = useSelector(allConnectionsForChatSelector);
  const chatType = connections.find((connection) => connection.connectionId === currentChat.connectionId)?.type;
  return chatType;
};

export const replaceWabaParams = (array: ParamsTemplateType[], component: WabaTemplateBody | WabaTemplateHeader) => {
  if (component && component.text && (array && array.length > 0)) {
    const findParams = component.text.split(/{{(\d+)}}/gm);

    return findParams.map((param, index) => {
      if (index % 2 === 1) {
        const paramItem = array.find((item) => item.id === Number(param));
        return paramItem && paramItem.value.length > 0 ? paramItem.value : `{{${param}}}`;
      }

      return param;
    });
  } else if (component) {
    return component.text;
  }
};

export const replaceCardWabaParams = (array: ParamsType[], text: string) => {
  if (text && (array && array.length > 0)) {
    const findParams = text.split(/{{(\d+)}}/gm);

    return findParams.map((param, index) => {
      if (index % 2 === 1) {
        const paramItem = array.find((item) => item.id === Number(param));
        return paramItem && paramItem.value.length > 0 ? paramItem.value : `{{${param}}}`;
      }

      return param;
    });
  } else if (text) {
    return text;
  }
};

export const errorWabaParams = (array: ParamsTemplateType[], id: number) => {
  const findParam = array.find((param) => param.id === id);
  if (findParam && findParam.value.length === 0) {
    return 'error';
  } else return '';
};
