import './InteractiveMessageTemplateCard.scss';
import { MessageTemplate } from '../../../../api/templates/types';
import InteractiveMessageTemplateCardBadges from '../InteractiveMessageTemplateCardBadges';
import TemplateCardButtons from '../../Components/TemplateCardButtons';


type Props = {
  template: MessageTemplate;
};

const InteractiveMessageTemplateCard: React.FC<Props> = ({ template }) => (
  <div className="interactiveMessageTemplateCard">
    <div className="interactiveMessageTemplateCard__container">
      <h3>{template.name}</h3>
      {template.params?.header?.text && <p className="regularText semibold">{template.params.header.text}</p>}
      <p className="regularText">{template.params.body}</p>
      {template.params?.footer && <p className="monospaceSmText">{template.params.footer}</p>}
      {template.params?.header?.type && <InteractiveMessageTemplateCardBadges type={template.params.header.type} />}
    </div>
    <TemplateCardButtons template={template} />
  </div>
);

export default InteractiveMessageTemplateCard;
