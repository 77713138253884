import React, { useCallback } from 'react';
import './WabaTemplateBase.scss';
import { useTranslation } from "react-i18next";
import { WabaTemplate } from '../../../../api/templates/types';
import { IHeaderTemplate, TextConvertHeaderType } from "../../../Broadcasts/BroadcastsTypes";
import { templateTypeViews } from "../ChatField/WabaTemplateComponent/consts";
import { getAttachmentsTypes, getWabaTemplateButtons, parseWabaText } from "../ChatField/helpers";
import { ReactComponent as AttachmentIcon } from "../../../../assets/attach.svg";


type InputCollection = string[];

type InputHeader = IHeaderTemplate[] | TextConvertHeaderType[];

type WabaTemplateProps = {
  template: WabaTemplate;
  setInputCollection: React.Dispatch<React.SetStateAction<InputCollection>>;
  setInputCollectionHeader: React.Dispatch<React.SetStateAction<InputCollection>>;
  attachedFiles: FileList;
  setAttachedFiles: React.Dispatch<React.SetStateAction<FileList>>;
  inputCollectionEdit?: InputCollection;
  inputCollectionHeaderEdit?: InputHeader;
  setInputCollectionHeaderEdit?: React.Dispatch<React.SetStateAction<InputHeader>>;
};

const WabaTemplateBase = ({
                            template,
                            setInputCollection,
                            setInputCollectionHeader,
                            attachedFiles,
                            setAttachedFiles,
                            inputCollectionEdit,
                            inputCollectionHeaderEdit = [],
                            setInputCollectionHeaderEdit
                          }: WabaTemplateProps) => {
  const { t } = useTranslation();

  const onChangeAttachmentFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) setAttachedFiles(e.target.files);
  };

  const getAttachmentInputType = useCallback(() => {
    const inputTypes: {
      [key: string]: {
        label: string;
        labelInfo: string;
      };
    } = {
      document: {
        label: t('chats.waba_templates.doc_label'),
        labelInfo: t('chats.waba_templates.doc_info'),
      },
      image: {
        label: t('chats.waba_templates.img_label'),
        labelInfo: t('chats.waba_templates.img_info'),
      },
      video: {
        label: t('chats.waba_templates.video_label'),
        labelInfo: t('chats.waba_templates.video_info'),
      },
    };
    const templateType = template.components.header?.format;

    const currentInputType = templateType && inputTypes[templateType];
    if (templateType && currentInputType) {

      return (
        <>
          <input
            type="file"
            id="waba-attachment"
            accept={getAttachmentsTypes(templateType)}
            onChange={onChangeAttachmentFile}
          />
          <label
            htmlFor="waba-attachment"
            className="templateContent_wabaTemplate__attachmentsContainer__label"
          >
            <AttachmentIcon />
            <span>{currentInputType?.label}</span>
          </label>
          <span className="templateContent_wabaTemplate__attachmentsContainer__labelInfo">
            {currentInputType?.labelInfo}
          </span>
        </>
      );
    }
    return null;
  }, []);

  const getAttachmentsPreview = (attachedFile: File, attachedUrl?: IHeaderTemplate) => {
    const templateType = template.components.header?.format;
    const TemplatePreviewComponent = templateTypeViews.get(templateType!);

    if (TemplatePreviewComponent) {
      return (
        <TemplatePreviewComponent
          attachedFile={attachedFile}
          customTemplateEditPayload={{
            fileUrl: attachedUrl?.url as string,
            documentName: attachedUrl?.text as string,
          }}
          key={attachedFile.name}
          setAttachedFiles={onDeleteFile}
          width={114}
          height={64}
        />
      );
    }

    return null;
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputCollection((prevState) => {
      const idx = parseInt(e.target.id, 10);
      const copy = [...prevState];
      copy[idx] = e.target.value;
      return copy;
    });
  };

  const onChangeHeaderInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputCollectionHeader((prevState) => {
      const idx = parseInt(e.target.id, 10);
      const copy = [...prevState];
      copy[idx] = e.target.value;
      return copy;
    });
  };

  const onDeleteFile = () => {
    // @ts-ignore
    setAttachedFiles([]);
    if (setInputCollectionHeaderEdit) {
      setInputCollectionHeaderEdit([]);
    }
  };

  return (
    <div className="templateContent">
      {template.components.header &&
        <div className="templateContent__title">
          {parseWabaText(template.components.header.text, onChangeHeaderInput)}
        </div>
      }
      <div className="templateContent_wabaTemplate">
        {parseWabaText(template.components.body.text, onChangeInput, inputCollectionEdit)}
        <div className="templateContent_wabaTemplate__buttonsContainer">
          {getWabaTemplateButtons(template)}
        </div>
        <div className="templateContent_wabaTemplate__attachmentsContainer">
          {(attachedFiles.length === 0 && inputCollectionHeaderEdit?.length === 0) && getAttachmentInputType()}
          <div
            className={`templateContent_wabaTemplate__attachmentsContainer_attachmentsPreview ${
              template.components.header?.format === 'document' ? 'list' : 'row'
            }`}
          >
            {/* @ts-ignore */}
            {(attachedFiles) && [...attachedFiles].map((attachment) => getAttachmentsPreview(attachment))}
            {(inputCollectionHeaderEdit) && [...inputCollectionHeaderEdit].map((attachment: IHeaderTemplate) =>
              getAttachmentsPreview({} as File, attachment))}
          </div>
        </div>
        {template.components.footer && (
          <p className="templateContent_wabaTemplate__footer">
            {parseWabaText(template.components.footer.text)}
          </p>
        )}
      </div>
    </div>
  );
};

export default WabaTemplateBase;
