import { useContext } from 'react';
import './ChatTemplateItem.scss';
import { MessageTemplate, WabaTemplate } from '../../../../../../api/templates/types';
import { chatTemplatesContext } from '../../ChatTemplatesProvider/ChatTemplatesProvider';
import { chatTemplatesMobileContext } from '../../ChatTemplatesMobileProvider/ChatTemplatesMobileProvider';
import { useMobile } from '../../../../../../components/utils/hooks';


type Props = {
  template: WabaTemplate | MessageTemplate;
};

const isWabaTemplate = (template: WabaTemplate | MessageTemplate): template is WabaTemplate => {
  return (template as WabaTemplate).locale !== undefined;
};

const ChatTemplateItem = ({ template }: Props) => {
  const { wabaTemplate, setWabaTemplate, customTemplate, setCustomTemplate } = useContext(chatTemplatesContext);
  const { setCurrentStep } = useContext(chatTemplatesMobileContext);
  const isMobile = useMobile();

  const onTemplate = () => {
    if (isWabaTemplate(template)) {
      setWabaTemplate(template);
    } else setCustomTemplate(template);
    if (isMobile) {
      setCurrentStep('preview');
    }
  };

  const active = isWabaTemplate(template) ? wabaTemplate?.name === template?.name : customTemplate?.id === template?.id;

  return (
    <div className={`chatTemplateItem ${active ? 'active' : ''}`} onClick={onTemplate}>
      <p className="smallText bold">
        {isWabaTemplate(template) ? (template.customName ? template.customName : template.name) : template.name}
      </p>
      <p className="smallText">{isWabaTemplate(template) ? template?.components?.body.text : template?.text}</p>
    </div>
  );
};

export default ChatTemplateItem;
