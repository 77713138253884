import { useFormContext } from 'react-hook-form';
import './ComboList.scss';


type ComboListProps = {
  name: string;
  values?: string[];
  children: React.ReactNode;
};

function ComboList({ name, values, children }: ComboListProps) {
  const { getValues, setValue } = useFormContext();
  const showValues = values?.filter((value) => value !== getValues(name));

  const onSelect = (e: React.MouseEvent<HTMLLIElement>, value: string) => {
    e.stopPropagation();
    setValue(name, value, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <div className='comboList'>
      {children}
      {showValues && showValues.length > 0 && (
        <ul>
          {showValues.map((value) => (
            <li key={value} onClick={(e) => onSelect(e, value)}>{value}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ComboList;
