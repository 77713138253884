import { useContext } from 'react';
import './ChatTemplateWabaHeader.scss';
import { WabaTemplateHeader } from '../../../../../../../../api/templates/types';
import { configChatTemplateWabaFile } from '../utils';
import { chatTemplatesFillContext } from "../../../../ChatTemplatesFillProvider/ChatTemplatesFillProvider";
import { replaceWabaParams } from '../../../../utils';
import ChatWabaTemplateFileType from "../../../ChatTemplateFill/components/ChatWabaTemplateFileType";


type Props = {
  header: WabaTemplateHeader;
};

const ChatTemplateWabaHeader = ({ header }: Props) => {
  const { file, headerParams } = useContext(chatTemplatesFillContext);

  return (
    <div className="chatTemplateWabaHeader">
      {file
        ? <ChatWabaTemplateFileType type={header.format} attachmentFile={file} />
        : (<>
            {header.format !== 'text' && (
              <div className="chatTemplateWabaHeader__file">{configChatTemplateWabaFile[header.format].icon}</div>
            )}
            {header.text && <p className="smallText semibold">{replaceWabaParams(headerParams, header)}</p>}
          </>)
      }
    </div>
  );
};

export default ChatTemplateWabaHeader;
