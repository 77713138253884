import { useEffect, useState } from 'react';
import { MessageTemplate } from '../../../../api/templates/types';
import { templateTypeViews } from '../ChatField/WabaTemplateComponent/consts';
import { getFileName } from '../../../../utils/utils';


type Props = {
  setText: React.Dispatch<React.SetStateAction<string>>;
  customTemplate: MessageTemplate;
  setSelectedCustomTemplate: React.Dispatch<React.SetStateAction<MessageTemplate | undefined>>;
};

const CustomTemplateAttachmentPreview: React.FC<Props> = ({ customTemplate, setText, setSelectedCustomTemplate }) => {
  const [documentName, setDocumentName] = useState<string | null>(null);
  const TemplatePreviewComponent = templateTypeViews.get(customTemplate.type);

  const onRemoveCustomTemplateAttachment = () => {
    setSelectedCustomTemplate(undefined);
  };

  useEffect(() => {
    if (customTemplate) {
      setText(customTemplate.text);
      if (customTemplate.fileUrl) {
        setDocumentName(getFileName(customTemplate.fileUrl));
      }
    }
  }, [customTemplate]);

  return (
    <>
      {TemplatePreviewComponent ? (
        <TemplatePreviewComponent
          customTemplateEditPayload={{ fileUrl: customTemplate.fileUrl!, documentName }}
          setAttachedFiles={onRemoveCustomTemplateAttachment}
          width={110}
          height={60}
        />
      ) : null}
    </>
  );
};

export default CustomTemplateAttachmentPreview;
