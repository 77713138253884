import React from 'react';
import './ChatTemplates.scss';
import { MessageTemplate } from '../../../../api/templates/types';
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';
import ChatTemplatesProvider from './ChatTemplatesProvider';
import ChatTemplatesWrapper from './ChatTemplatesWrapper';
import ChatTemplatesMobileProvider from './ChatTemplatesMobileProvider';
import ChatTemplatesFillProvider from './ChatTemplatesFillProvider';


type Props = {
  onClose: () => void;
  setMessageTemplate: React.Dispatch<React.SetStateAction<MessageTemplate | undefined>>;
  wabaDialogOnly?: boolean;
};

const ChatTemplates = ({ onClose, setMessageTemplate, wabaDialogOnly = false }: Props) => {
  return (
    <div className="chatTemplates">
      <CloseIcon className="closeIcon" onClick={onClose} />
      <ChatTemplatesProvider onClose={onClose} setMessageTemplate={setMessageTemplate} wabaDialogOnly={wabaDialogOnly}>
        <ChatTemplatesMobileProvider>
          <ChatTemplatesFillProvider>
            <ChatTemplatesWrapper />
          </ChatTemplatesFillProvider>
        </ChatTemplatesMobileProvider>
      </ChatTemplatesProvider>
    </div>
  );
};

export default ChatTemplates;
