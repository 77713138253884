import { useContext, useEffect, useState } from 'react';
import './WabaTemplatesModeration.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useAppSelector } from '../../../../store/store';
import { currentCompanySelector } from '../../../Chat/companiesSelector';
import { CarouselExample, CreateWabaTemplateRequestExamples, WabaTemplateComponentButtonsButtonUrl } from '../../templatesTypes';
import { uploadWabaTemplateFile } from '../../templatesAPI';
import { wabaModeration } from './context';
import { wabaTemplateContext } from '../WabaTemplateProvider/WabaTemplateProvider';
import { useWabaTemplateRequest } from '../api';
import { getButtonsUrlParams, getTextParams, IndexedParams, ParamsType } from './utils';
import WabaFileLoad from './WabaFileLoad';
import WabaPreview from '../WabaPreview';
import WabaParams from './WabaParams';
import Button from '../../../../components/Button';
import { ReactComponent as ArrowBack } from '../../../../assets/arrow-back.svg';
import { ReactComponent as SmallLoader } from '../../../../assets/small-loader.svg';


const WabaTemplatesModeration = () => {
  const { t } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const [onAttachmentFile, setOnAttachmentFile] = useState<File>();
  const [arrayHeaderParams, setArrayHeaderParams] = useState<ParamsType[]>([]);
  const [arrayBodyParams, setArrayBodyParams] = useState<ParamsType[]>([]);
  const [arrayButtonParams, setArrayButtonParams] = useState<ParamsType[]>([]);
  const [arrayCardParams, setArrayCardParams] = useState<IndexedParams[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const { push, goBack } = useHistory();
  const loading = useAppSelector((state) => state.templates.wabaListLoading);
  const {
    connectionId, wabaTemplateState, updateWabaTemplateState, previewWabaTemplate
  } = useContext(wabaTemplateContext);
  const { makeWabaTemplate, makeWabaTemplateRequest } = useWabaTemplateRequest();
  const { header, body, buttons, cards } = wabaTemplateState;

  const wabaValues = {
    onAttachmentFile, setOnAttachmentFile,
    arrayHeaderParams, setArrayHeaderParams,
    arrayBodyParams, setArrayBodyParams,
    arrayButtonParams, setArrayButtonParams,
    arrayCardParams, setArrayCardParams,
    isError
  };

  useEffect(() => () => {
    if (!window.location.pathname.endsWith('create')) {
      updateWabaTemplateState({ type: 'clear', payload: undefined });
    } else {
      updateWabaTemplateState({ type: 'stage', payload: { stage: 'form' } });
    }
  }, []);

  useEffect(() => {
    if (wabaTemplateState.stage === 'form') goBack();
  }, [wabaTemplateState.stage]);

  const goToBack = () => {
    updateWabaTemplateState({ type: 'stage', payload: { stage: 'form' } });
  };

  useEffect(() => {
    if (header?.text) setArrayHeaderParams(getTextParams(header.text));
    if (body) setArrayBodyParams(getTextParams(body));
    if (buttons) setArrayButtonParams(getButtonsUrlParams(buttons.filter(
      (item) => item.type === 'URL') as WabaTemplateComponentButtonsButtonUrl[]));
    if (cards) {
      const cardsParams: IndexedParams[] = [];
      cards.forEach((card, index) => {
        cardsParams.push({ id: index, params: getTextParams(card.description) });
      });
      setArrayCardParams(cardsParams);
    }
  }, []);

  const onSubmit = async () => {
    const hasEmptyHeaderParams = arrayHeaderParams.some((item) => item.value === '');
    const hasEmptyBodyParams = arrayBodyParams.some((item) => item.value === '');
    const hasEmptyButtonParams = arrayButtonParams.some((item) => item.value === '');
    const hasEmptyCardParams = arrayCardParams.some((item) => item.params.some((param) => param.value === ''));
    const hasHeaderError = (header && header.format !== 'TEXT') && !onAttachmentFile || false; 
      
    const error = hasEmptyHeaderParams || hasEmptyBodyParams || hasEmptyButtonParams || hasEmptyCardParams
      || hasHeaderError;
    setIsError(error);
    if (error) return;

    const headerItems = arrayHeaderParams.map((item) => item.value);
    const bodyItems = arrayBodyParams.map((item) => item.value);
    const buttonUrlItems = arrayButtonParams.map((item) => item.value);
    const headerUrl = onAttachmentFile &&
      (await uploadWabaTemplateFile({ companyId: currentCompany.id, file: onAttachmentFile }).then((res) => {
        return res.url;
      }));

    let examples: CreateWabaTemplateRequestExamples = {};
    if (wabaTemplateState.kind === 'message') {
      examples = {
        ...(headerItems.length > 0 ? { header_text: headerItems } : {}),
        ...(bodyItems.length > 0 ? { body_text: bodyItems } : {}),
        ...(buttonUrlItems && buttonUrlItems.length > 0 ? { button_url_text: buttonUrlItems } : {}),
        ...(headerUrl ? { header_url: headerUrl } : {}),
      };
    }
    if (wabaTemplateState.kind === 'carousel') {
      examples = { ...(bodyItems.length > 0 ? { body_text: bodyItems } : {}) }
      if (cards) {
        const carouselExamples: CarouselExample[] = [];
        cards.forEach((card, index) => {
          const cardItems = arrayCardParams[index].params.map((item) => item.value);
          carouselExamples.push( {
            ...(cardItems.length > 0 ? { body_text: cardItems } : {}),
            ...(headerUrl ? { header_url: headerUrl } : {}),
            ...(buttonUrlItems && buttonUrlItems.length > 0 ? { button_url_text: buttonUrlItems } : {}),
          });
        });
        examples.carousel_cards = carouselExamples;
      }
    }

    const template = makeWabaTemplateRequest(wabaTemplateState);
    if (template) {
      template.examples = examples;
      await makeWabaTemplate(template).then((res) => {
        if (res) push({ pathname: location.pathname.replace('moderation', 'list'), state: { connectionId } });
      });
    }
  };

  return (
    <div className={`wabaTemplatesModeration ${loading && 'loading'}`}>
      <div className="wabaTemplatesModeration__header">
        <Button type='button' textType='small' text={t('back')} color="white" image={<ArrowBack />} onClick={goToBack} />
        <h1>{t('templates.waba_moderation.title')}</h1>
      </div>
      <wabaModeration.Provider value={wabaValues}>
        <div className="wabaTemplatesModeration__content">
          <div className="wabaTemplatesModeration__content_params">
            {header && header.format !== 'TEXT' && (
              <div className="wabaTemplatesModeration__content_params_title">
                <p className="regularText middle">{t('templates.waba_moderation.header_param')}</p>
                <WabaFileLoad format={header.format} />
              </div>
            )}
            <WabaParams />
            <Button type="button" color="orange" textType="regular" text={t('templates.waba_moderation.submit')}
              onClick={onSubmit} disabled={loading} image={loading ? <SmallLoader /> : <></>} />
          </div>
          <div className="wabaTemplatesModeration__content_preview">
            <h3>{t('templates.waba_moderation.preview')}</h3>
            <WabaPreview body={previewWabaTemplate?.body} header={previewWabaTemplate?.header}
              footer={previewWabaTemplate?.footer} buttons={previewWabaTemplate?.buttons}
              carousel={previewWabaTemplate?.carousel} />
          </div>
        </div>
      </wabaModeration.Provider>
    </div>
  );
};

export default WabaTemplatesModeration;
