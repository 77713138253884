import './MessageTemplateCard.scss';
import { MessageTemplate } from '../../../../api/templates/types';
import TemplateCardButtons from '../../Components/TemplateCardButtons';


type Props = {
  template: MessageTemplate;
};

const MessageTemplateCard: React.FC<Props> = ({ template }) => (
  <div className="messageTemplateCard">
    <div className="messageTemplateCard__content">
      <h3>{template.name}</h3>
      <p className="regularText">{template.text}</p>
    </div>
    <TemplateCardButtons template={template} />
  </div>
);

export default MessageTemplateCard;
