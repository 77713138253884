import { useEffect, useRef, useState } from 'react';
import './Chat.scss';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from '../../../store/store';
import { fetchChatWithContactByContactId } from '../chatsSlice';
import { chatsWithContactsSelector, currentChatSelector } from '../chatsSelector';
import { allConnectionsForChatSelector } from '../companiesSelector';
import { fetchMessages } from '../messagesThunks';
import { urlSelector } from '../billSelector';
import { messagesSelector } from '../messagesSelector';
import { WrapperPermission } from '../../../utils/WrapperPermission/WrapperPermission';
import { updateIsViewNavbarMobile } from "../companiesSlice";
import { ChatsWithContacts } from '../ChatTypes';
import { ChatContext, chatContext } from "./ChatContent/StickyViewport/Context";
import ChatHeader from './ChatHeader/ChatHeader';
import ChatContent from './ChatContent/ChatContent';
import ChatField from './ChatField/ChatField';
import ProfileContact from '../../Contacts/components/ProfileContact';
import ModalPaymentInvoiceLink from '../ChatBill/components/ModalPaymentInvoiceLink';
import Loader from '../../../components/Loader/Loader';
import ProfileGroup from './ProfileGroup';


function Chat() {
  const dispatch = useAppDispatch();
  const params = useRouteMatch<{ companyId: string; contactId: string; chatId: string }>(
  '/companies/:companyId/chats/:contactId/:chatId'
  );
  const chatsWithContacts = useSelector(chatsWithContactsSelector);
  const currentChat = useSelector(currentChatSelector);
  const sources = useSelector(allConnectionsForChatSelector);
  const urlInvoices = useSelector(urlSelector);
  const messages = useSelector(messagesSelector);
  const chatContentRef = useRef<HTMLDivElement>(null);
  const [visibleProfile, setVisibleProfile] = useState(false);
  const [activeContact, setActiveContact] = useState<ChatsWithContacts | null>();

  useEffect(() => {
    if (params) {
      let contact = chatsWithContacts?.data.find((chat) => chat.contactId === Number(params.params.contactId));
      if (!contact) {
        dispatch(fetchChatWithContactByContactId({
          companyId: Number(params.params.companyId),
          contactId: Number(params.params.contactId),
          chatId: Number(params.params.chatId),
        })).then(res => {
          if (res.meta.requestStatus === 'fulfilled' && typeof (res.payload) === 'object') {
            contact = res.payload as ChatsWithContacts;      
          }
        });
      }
      setActiveContact(contact);
    }

    return () => setActiveContact(null);

  }, [params]);

  useEffect(() => {
    if (currentChat.chatId 
        && (currentChat.chatId !== Number(params?.params.chatId) || !messages)
        && params?.params.companyId) {
      dispatch(fetchMessages({
        companyId: Number(params?.params.companyId),
        connectionId: currentChat.connectionId,
        chatId: currentChat.chatId,
        limit: 10,
      }));
      dispatch(updateIsViewNavbarMobile(false));
    }
  }, [currentChat.chatId]);

  const contextValue: ChatContext = {
    chatContentRef,
    contactName: activeContact?.contactName,
    avatarUrl: activeContact?.avatarUrl,
    isGroup: activeContact?.isGroup,
    chatPhone: activeContact?.isGroup
      ? sources.find((source) => source.connectionId === currentChat?.connectionId)?.name
      : currentChat?.phone ? `+${currentChat?.phone}` : ''
  };

  const toggleVisibleProfile = () => setVisibleProfile((prev) => !prev);

  if (!activeContact) {
    return (
      <Loader />
    );
  }

  return (
    <> 
      {urlInvoices && <ModalPaymentInvoiceLink />}
      <div className="chat">
        <chatContext.Provider value={contextValue}>
          <ChatHeader toggleVisibleProfile={toggleVisibleProfile} />
          <ChatContent />
          <ChatField />
        </chatContext.Provider>
      </div>
      {visibleProfile && (activeContact.isGroup
        ? <ProfileGroup name={activeContact.contactName} avatarUrl={activeContact.avatarUrl}
            toggleVisibleProfile={toggleVisibleProfile} />
        : <WrapperPermission permission="contactsView">
            <ProfileContact toggleVisibleProfile={toggleVisibleProfile} />
          </WrapperPermission>
      )} 
    </>
  );
}

export default Chat;
