import { useEffect } from 'react';
import './Templates.scss';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { currentCompanySelector } from '../Chat/companiesSelector';
import { fetchMessageTemplates } from './templatesSlice';
import MessageTemplates from './MyTemplates/MessageTemplates';
import MessageTemplateSettings from './MyTemplates/MessageTemplateSettings';
import InteractiveMessages from './InteractiveTemplates/InteractiveMessages/InteractiveMessages';
import InteractiveMessageSettings from './InteractiveTemplates/InteractiveMessageSettings/InteractiveMessageSettings';
import TemplatesList from './TemplatesList';
import WabaTemplates from "./WabaTemplates";
import WabaTemplatesList from './WabaTemplates/WabaTemplatesList';
import WabaTemplateForm from './WabaTemplates/WabaTemplateForm';
import WabaTemplatesModeration from './WabaTemplates/WabaTemplatesModeration';
import WabaTemplateProvider from './WabaTemplates/WabaTemplateProvider';


const TemplatesRoute = () => {
  const params = useRouteMatch();
  const dispatch = useDispatch();
  const { id: companyId } = useSelector(currentCompanySelector);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchMessageTemplates({ companyId }));
    }
  }, [companyId]);

  return (
    <>
      <TemplatesList />
      <div className="templatesContent">
        <Switch>
          <Route path={`${params.url}/my-templates`} exact>
            <MessageTemplates />
          </Route>
          <Route path={`${params.path}/my-templates/create`} exact>
            <MessageTemplateSettings />
          </Route>
          <Route path={`${params.path}/my-templates/edit/:templateId`} exact>
            <MessageTemplateSettings />
          </Route>
          <Route path={`${params.path}/interactive-messages`} exact>
            <InteractiveMessages />
          </Route>
          <Route path={`${params.path}/interactive-messages/create`} exact>
            <InteractiveMessageSettings />
          </Route>
          <Route path={`${params.path}/interactive-messages/edit/:templateId`} exact>
            <InteractiveMessageSettings />
          </Route>
          <WabaTemplateProvider>
            <Route path={`${params.url}/waba-templates`} exact>
              <WabaTemplates />
            </Route>
            <Route path={`${params.url}/waba-templates/list`} exact>
              <WabaTemplatesList />
            </Route>
            <Route path={`${params.url}/waba-templates/create`} exact>
              <WabaTemplateForm />
            </Route>
            <Route path={`${params.url}/waba-templates/moderation`} exact>
              <WabaTemplatesModeration />
            </Route>
            <Route path={params.url} exact>
              <Redirect to={`${params.url}/my-templates`} />
            </Route>
          </WabaTemplateProvider>
        </Switch>
      </div>
    </>
  );
};

export default TemplatesRoute;
