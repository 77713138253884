import regexifyString from 'regexify-string';
import { MessageTemplate, WabaTemplate } from '../../../../api/templates/types';


export const getTemplatesFromActiveTab = <T extends MessageTemplate | WabaTemplate>(
  templates: T[],
  getTemplateTextContent: (template: T) => string,
  onSelectTemplate: (template: T, e: React.MouseEvent<HTMLDivElement>) => void,
  getKey: (template: T) => string
) =>
  templates.map((template) => (
    <div className="template__content" key={getKey(template)} onClick={(e) => onSelectTemplate(template, e)}>
      <p>{getTemplateTextContent(template)}</p>
    </div>
  ));

export const getSearchedTemplate = <T extends MessageTemplate | WabaTemplate>(
  templates: T[],
  predicate: (template: T) => boolean,
  getTemplateTextContent: (template: T) => string,
  onSelectTemplate: (template: T, e: React.MouseEvent<HTMLDivElement>) => void,
  getKey: (template: T) => string
): React.ReactNodeArray => {
  const searchedTemplates = templates.filter((template) => predicate(template));

  return searchedTemplates.map((template) => (
    <div className="template__content" key={getKey(template)} onClick={(e) => onSelectTemplate(template, e)}>
      <p>{getTemplateTextContent(template)}</p>
    </div>
  ));
};

export const parseWabaText = (text: string, onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void,
                              inputCollectionEdit?: string[]) =>
  regexifyString({
    pattern: /{{(\d+)}}/gm,
    decorator: (match, index) => <input id={index.toString()} onChange={onChangeInput}
                                        defaultValue={inputCollectionEdit && inputCollectionEdit[index]} key={index}/>,
    input: text,
  });

export const getWabaTemplateButtons = (template: WabaTemplate) => {
  if (template.components.buttons) {
    return template.components.buttons.buttons.map((button) => {
      if (button.type === 'url') {
        return (
          <a href={button.url} target="_blank" rel="noreferrer" key={button.text}>
            {button.text}
          </a>
        );
      }
      return (
        <button type="button" key={button.text}>
          {button.text}
        </button>
      );
    });
  }
  return null;
};

export const getAttachmentsTypes = (templateType: string) => {
  switch (templateType) {
    case 'document':
      return '.pdf';
    case 'image':
      return '.jpeg,.png';
    case 'video':
      return 'video/mp4,video/3gpp';
    default:
      return '*/';
  }
};

export const validateAttachmentFileType = (filetype: string) => {
  const fileTypes = [
    'audio/aac',
    'audio/mp4',
    'audio/amr',
    'audio/mpeg',
    'audio/ogg',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/3gpp',
  ];
  return fileTypes.some((type) => type === filetype);
};
