import { ReactElement } from "react";
import { WabaTemplate } from "../../../../../../../api/templates/types";
import { WabaTemplateHeaderFormat } from "../../../../../ChatTypes";
import { ReactComponent as ImageIcon } from '../../../../../../../assets/image.svg';
import { ReactComponent as VideoIcon } from '../../../../../../../assets/video.svg';
import { ReactComponent as FilesIcon } from '../../../../../../../assets/files.svg';
import { ReactComponent as PhoneIcon } from '../../../../../../../assets/phone-outgoing.svg';
import { ReactComponent as NewWindowIcon } from '../../../../../../../assets/new-window.svg';
import { ReactComponent as CopyIcon } from '../../../../../../../assets/copy.svg';


type ChatTemplateWabaFileConfig = {
  title: string;
  description: string;
  accept: string;
  maxSize: number,
  sizeMB: number,
  icon: ReactElement;
}
type ChatTemplateWabaButtonConfig = {
  icon?: ReactElement;
};

export const configChatTemplateWabaFile: Record<WabaTemplateHeaderFormat, ChatTemplateWabaFileConfig> = {
  text: {
    title: '',
    description: '',
    accept: '',
    maxSize: 0,
    sizeMB: 0,
    icon: <></>
  },
  image: {
    title: 'chats.templates.fill.image_label',
    description: 'chats.templates.fill.image_info',
    accept: '.png, .jpeg, .jpg',
    maxSize: 5242880,
    sizeMB: 5,
    icon: <ImageIcon />
  },
  video: {
    title: 'chats.templates.fill.video_label',
    description: 'chats.templates.fill.video_info',
    accept: '.mp4, .3gp',
    maxSize: 16777216,
    sizeMB: 16,
    icon: <VideoIcon />
  },
  document: {
    title: 'chats.templates.fill.doc_label',
    description: 'chats.templates.fill.doc_info',
    accept: '.pdf, .txt, .docx, .doc, .rtf, .odt, .xml',
    maxSize: 104857600,
    sizeMB: 100,
    icon: <FilesIcon />
  },
}

export const configWabaTemplateButton: Record<string, ChatTemplateWabaButtonConfig> = {
  quick_reply: {},
  phone_number: { icon: <PhoneIcon /> },
  url: { icon: <NewWindowIcon /> },
  otp: { icon: <CopyIcon /> },
};

export const isSendToChatButton = (template?: WabaTemplate) => {
  if (template) {
    const headerParams =
      template.components.header && (template.components.header.paramsCount > 0 ||
      template.components.header.format !== 'text');
    const bodyParams =
      template.components.body && template.components.body.paramsCount > 0;
    const footerParams = template.components.footer && template.components.footer.paramsCount > 0;
    let carouselParams: boolean = false;
    if (template.components.carousel) carouselParams = true;

    return headerParams || bodyParams || footerParams || carouselParams || false;
  } else return false;
};
