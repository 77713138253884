import cleanDeep from 'clean-deep';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useAppDispatch } from '../../../store/store';
import { createInteractiveTemplate, editInteractiveTemplate } from '../templatesSlice';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { uploadFile } from '../../Chat/ChatAPI';
import { InteractiveHeader, InteractiveType } from '../../../api/templates/types';
import { ButtonAction, CreateInteractiveTemplateRequestData, ListActions } from '../templatesTypes';


export type InteractiveTemplate = {
  name: string;
  type: InteractiveType;
  body: string;
  header: {
    type: InteractiveHeader;
    text: string | null;
    url: string | null;
    filename: string | null;
  };
  footer: string | null;
  action: {
    buttons?: ButtonAction[] | null;
    sections?: ListActions | null;
  };
};

export function useInteractiveTemplateSchema() {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field'))
      .max(128, (max) => t('error.form.max_length', { limit: max.max })),
    type: yup.string().required(),
    body: yup.string().required(t('error.form.empty_field'))
      .max(1024, (max) => t('error.form.max_length', { limit: max.max })),
    header: yup.object().nullable(true).shape({
      type: yup.string().required(),
      text: yup.string().when('type', {
        is: 'text',
        then: yup.string().nullable(true).transform((_, val) => (val !== "" ? val : null))
          .required(t('error.form.empty_field')).max(60, (max) => t('error.form.max_length', { limit: max.max })),
        otherwise: yup.string().nullable()
    }),
      url: yup.string().when('type', {
        is: (typeFile: string) => ['document', 'image', 'video'].includes(typeFile),
        then: yup.string().nullable(true).transform((_, val) => (val !== "" ? val : null))
          .required(t('templates.add_interactive_templates.template_header.header_dropzone.errors.attachment')),
        otherwise: yup.string().nullable(true).transform((_, val) => (val !== "" ? val : null))
      }),
    }),
    action: yup.object().when('type', {
      is: 'button',
      then: yup.object().shape({
        buttons: yup.array().max(3).of(yup.object().shape({
          type: yup.string().required(),
          reply: yup.object().shape({
            title: yup.string().required(t('templates.add_interactive_templates.template_actions.buttons.errors.add'))
            .max(20, (max) => t('error.form.max_length', { limit: max.max })),
          })
        }))
        .test({
          name: 'buttons',
          test: (val, { createError }) => {
            if (val) {
              const titles = new Set(val.map((button) => button.reply.title));
              return titles.size < val?.length
                ? createError({
                  path: 'action.buttons',
                  message: t('templates.add_interactive_templates.template_actions.buttons.errors.unique_titles') })
                : true;
            }
            return true;
          }
        })
      }),
      otherwise: yup.object().shape({
        sections: yup.object().shape({
          button: yup.string().required(t('error.form.empty_field'))
            .max(20, ({ max }) => t('error.form.max_length', { limit: max })),
          sections: yup.array().max(10).of(yup.object().shape({
            title: yup.string().nullable().max(24, ({ max }) => t('error.form.max_length', { limit: max })),
            rows: yup.array().max(10).of(yup.object().shape({
              title: yup.string().required(t('error.form.empty_field'))
                .max(24, (max) => t('error.form.max_length', { limit: max.max })),
              description: yup.string().nullable().max(72, (max) => t('error.form.max_length', { limit: max.max })),
            }))
          })),
        })
      })
    }),
    footer: yup.string().nullable(true).transform((_, val) => (val !== "" ? val : null))
      .max(1024, (max) => t('error.form.max_length', { limit: max.max })),
  });
    
  return schema;
}

export function useInteractiveTemplate() {
  const dispatch = useAppDispatch();
  const { id: companyId } = useSelector(currentCompanySelector);
 
  const onSubmit = async (data: InteractiveTemplate, attachedFile?: File, templateId?: number) => {
    let result = false;
    let actionType;
    let header:
      | {
          type: InteractiveHeader;
          text?: string;
          url?: string;
          filename?: string;
        }
      | undefined;

    if (data.type === 'button') {
      actionType = {
        key: 'buttons',
        data: data.action.buttons,
      };
    } else {
      actionType = {
        key: 'sections',
        data: cleanDeep(data.action.sections),
      };
    }

    if (data.header === null) {
      header = undefined;
    } else if (['document', 'image', 'video'].includes(data.header.type)) {
      if (attachedFile && companyId) {
        const { url: FileUrl } = await uploadFile({ file: attachedFile, companyId });
        header = {
          type: data.header.type,
          url: FileUrl,
          filename: attachedFile.name,
        };
      } else {
        header = {
          type: data.header.type,
          text: data.header.text || undefined,
          url: data.header.url || undefined,
          filename: data.header.filename || undefined,
        };
      }
    } else if (data.header.type === 'text') {
      header = {
        type: data.header.type,
        text: data.header.text || undefined,
      };
    }

    const postPayload : CreateInteractiveTemplateRequestData = {
      type: 'waba_interactive',
      name: data.name,
      waba_interactive: {
        type: data.type,
        header,
        body: data.body,
        footer: data.footer,
        action: {
          [actionType.key]: actionType.data,
        },
      },
      company_id: companyId,
    } ;

    if (templateId) {
      await dispatch(editInteractiveTemplate({ ...postPayload, templateId })).then((response) => {
        if (response.payload) result = response.meta.requestStatus === 'fulfilled';
      });
    } else {
      await dispatch(createInteractiveTemplate(postPayload)).then((response) => {
        if (response.payload) result = response.meta.requestStatus === 'fulfilled';
      });
    }

    return result;
  };

  return { onSubmit };
}
