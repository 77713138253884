import { useState } from 'react';
import './LogItem.scss';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { broadcastsSourceSelector } from '../../BroadcastsSelector';
import { finishBroadcast } from '../../BroadcactsSlice';
import { IBroadcast } from '../../BroadcastsTypes';
import { BroadcastsServicesType, broadcastErrorAlias, calcPercentage } from '../../utils';
import { useExportStatistic } from '../../api';
import ActionDialog from '../../../../components/Modals/ActionDialog';
import ItemConnection from '../ItemConnection';
import ItemNameDate from '../ItemNameDate';
import ItemRecievers from '../ItemRecievers';
import ItemStatistic from '../ItemStatistic';
import ItemStatus from '../ItemStatus/ItemStatus';


type LogItemProps = {
  item: IBroadcast;
};

function LogItem({ item }: LogItemProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId/broadcasts');
  const connections = useSelector(broadcastsSourceSelector);
  const connection = connections.find(con => con.connectionId === item.connectionId);
  const [stopOpen, setStopOpen] = useState<boolean>(false);
  const { download, ref, file, fileName } = useExportStatistic(
    { companyId: url?.params.companyId, broadcastId: item.id, name: item.name });

  const onPreview = () => {
    history.push({
      pathname: `${url?.url}/${item.id}`,
      state: item
    });
  };

  const onStop = () => {
    setStopOpen(true);
  };

  const onExport = () => download();

  const stopBroadcast = () => {
    if (url?.params.companyId) {
      dispatch(finishBroadcast({ companyId: Number(url.params.companyId), broadcastId: item.id, status: 'FINISHED' }));
      setStopOpen(false);
    }
  };

  const getErrorMessage = () => {
    if (item.errorType) {
      return t(`broadcasts.error.${broadcastErrorAlias[item.errorType]}`);
    }
    return undefined;
  };

  return (
    <>
    {stopOpen &&
      <ActionDialog action={t('stop')} cancel={t('cancel')} onAction={stopBroadcast} setIsOpen={setStopOpen}
        title={t('broadcasts.stop.caption')} message={t('broadcasts.stop.message')} />}
      <div className='log_list__item' onClick={onPreview}>
        <ItemNameDate name={item.name} date={item.plannedTime} />
        <ItemConnection name={connection?.name} service={connection?.type as BroadcastsServicesType} />
        <ItemRecievers recievers={item.statistics.total} />
        <ItemStatistic quantity={item.statistics.delivered}
          percentage={calcPercentage(item.statistics.total, item.statistics.delivered)} />
        <ItemStatistic quantity={item.statistics.read}
          percentage={calcPercentage(item.statistics.total, item.statistics.read)} />
        <ItemStatus broadcastStatus={item.status} onExport={onExport} onStop={onStop} message={getErrorMessage()} />
      </div>
      <a ref={ref} className='hidden' href={file} download={fileName}>statistic</a>
    </>
  );
}

export default LogItem;
