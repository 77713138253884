import React, { useEffect, useState } from 'react';
import './NotificationCreateTelegram.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createNotificationsChannelsAPI } from '../../NotificationsAPI';
import { NotificationChannelTypes, TelegramNotificationChannel } from '../../NotificationsTypes';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { RootState } from '../../../../../store/store';
import { clearNotification } from '../../NotificationsSlice';
import { ReactComponent as CopyIcon } from '../../../../../assets/copy.svg';
import ListItems from '../../../../../components/ListItems/ListItems';
import SidePanel from '../../../../../components/Modals/SidePanel';


type Props = {
  setChannelConnection: React.Dispatch<NotificationChannelTypes | null>;
};

const NotificationPanelTg = ({ setChannelConnection }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const newChannel = useSelector((state: RootState) => state.notifications.notification);
  const [dataQrCode, setDataQrCode] = useState<TelegramNotificationChannel>();

  useEffect(() => {
    const loadingQrCode = async () => {
      const data = await createNotificationsChannelsAPI({
        companyId: currentCompany.id,
        type: 'telegram',
        params: { events: [] },
      });
      setDataQrCode(data as TelegramNotificationChannel);
    };

    loadingQrCode();
  }, []);

  useEffect(() => {
    if (newChannel) {
      dispatch(clearNotification());
      dispatch(addAlertWithCustomText({ message: t('settings.notifications.success'), type: 'info' }));
      setChannelConnection(null);
    }
  }, [newChannel]);

  const onClose = () => {
    setChannelConnection(null);
  };

  const onCopy = () => {
    if (dataQrCode?.url) {
      navigator.clipboard.writeText(dataQrCode.url);
      dispatch(addAlertWithCustomText({ message: t('settings.users.copy_link'), type: 'info' }));
    }
  };

  const onOpenLink = () => {
    if (dataQrCode) window.open(dataQrCode.url, '_blank');
  };

  return (
    <SidePanel setIsOpen={onClose} background="light">
      <div className="notificationPanelTelegram">
        <h1>{t('settings.notifications.title_tg')}</h1>
        <p className="regularText">{t('settings.notifications.description_panel')}</p>
        <img alt="qr code" src={dataQrCode?.qrCode || ''} />
        <ListItems typeList="ol" items={t('settings.notifications.steps').split('\n')} />
        <div className="notificationPanelTelegram__link">
          <input className="smallText" type="text" readOnly={true}
            value={dataQrCode ? dataQrCode.url : ''} onClick={onOpenLink} />
          <CopyIcon type="click" onClick={onCopy} />
        </div>
      </div>
    </SidePanel>
  );
}

export default NotificationPanelTg;
