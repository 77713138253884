import './LogList.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { broadcastsLogSelector } from '../../BroadcastsSelector';
import LogItem from '../LogItem';


function LogList() {
  const { t } = useTranslation();
  const loglist = useSelector(broadcastsLogSelector);

  return (
    <>
    {loglist.length > 0
      ? loglist.map((item) =><LogItem key={item.id} item={item} />)
      : (<div className='noPlan'><p>{t('broadcasts.log.no_log')}</p></div>)
    }
    </>
  );
};

export default LogList;
