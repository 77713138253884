import { useContext, useEffect, useState } from 'react';
import './ChatTemplateFill.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import { chatTemplatesContext } from '../../ChatTemplatesProvider/ChatTemplatesProvider';
import { chatTemplatesFillContext } from '../../ChatTemplatesFillProvider/ChatTemplatesFillProvider';
import { chatTemplatesMobileContext } from '../../ChatTemplatesMobileProvider/ChatTemplatesMobileProvider';
import { useMobile } from '../../../../../../components/utils/hooks';
import { useSubmitChatWabaTemplate } from '../../api';
import { getButtonsUrlParams, getTextParams, IndexedParams } from "./utils";
import { ReactComponent as ArrowBackIcon } from '../../../../../../assets/arrow-back.svg';
import { ReactComponent as ArrowLeft } from '../../../../../../assets/arrow-left.svg';
import { ReactComponent as SmallLoader } from '../../../../../../assets/small-loader.svg';
import Button from '../../../../../../components/Button';
import ChatTemplateFillHeader from './components/ChatTemplateFillHeader';
import FillParams from './components/FillParams';
import FillCardParams from "./components/FillCardParams";


const ChatTemplateFill = () => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const [isError, setIsError] = useState<boolean>(false);
  const { wabaTemplate } = useContext(chatTemplatesContext);
  const { setCurrentStep } = useContext(chatTemplatesMobileContext);
  const {
    setFillTemplate, file, setFile, cardFiles, setCardFiles,
    headerParams, setHeaderParams,
    bodyParams, setBodyParams,
    arrayButtonParams, setArrayButtonParams,
    arrayCardParams, setArrayCardParams, arrayCardButtonsParams, setArrayCardButtonsParams,
  } = useContext(chatTemplatesFillContext);
  const onSubmitTemplate = useSubmitChatWabaTemplate();
  const loader = useSelector((state: RootState) => state.chats.wabaLoader);
  const header = wabaTemplate?.components.header;
  const cards = wabaTemplate?.components.carousel?.cards;

  useEffect(() => {
    const body = wabaTemplate?.components.body;
    const buttons = wabaTemplate?.components.buttons;

    if (header?.text && headerParams.length === 0) setHeaderParams(getTextParams(header.text));
    if (body && bodyParams.length === 0) setBodyParams(getTextParams(body.text));
    if (buttons && arrayButtonParams.length === 0) setArrayButtonParams(getButtonsUrlParams(buttons.buttons.filter(
      (item) => item.type === 'url')));
    if (cards) {
      const cardsParams: IndexedParams[] = [];
      const cardsButtonsParams: IndexedParams[] = [];
      cards.forEach((card, index) => {
      cardsParams.push({ id: index, params: getTextParams(card.body.text) });
      if (card.buttons) {
        cardsButtonsParams.push({ id: index, params: getButtonsUrlParams(card.buttons.buttons.filter(
          (item) => item.type === 'url')) });
        }
      });
      if (arrayCardButtonsParams.length === 0) setArrayCardButtonsParams(cardsButtonsParams);
      if (arrayCardParams.length === 0) setArrayCardParams(cardsParams);
      if (cardFiles === undefined) setCardFiles(Array(cards.length));
    }
  }, []);

  const onBack = () => {
    setFillTemplate(false);
    setHeaderParams([]);
    setBodyParams([]);
    setArrayButtonParams([]);
    setArrayCardParams([]);
    setArrayCardButtonsParams([]);
    setCardFiles(undefined);
    setFile(undefined);
    if (isMobile) {
      setCurrentStep('preview');
    }
  };

  const onSend = async () => {
    await onSubmitTemplate(setIsError);
  };

  return (
    <div className="chatTemplateFill">
      <div className="chatTemplateFill__header">
        {isMobile && <ArrowLeft className="arrowBack" onClick={onBack} />}
        {isMobile ? <h3>{t('chats.templates.fill.title')}</h3> : <h4>{t('chats.templates.fill.title')}</h4>}
      </div>
      <div className="chatTemplateFill__content">
        {header && header.format !== 'text' &&
          <ChatTemplateFillHeader format={header.format} isError={isError} file={file} setFile={setFile} />}
        <FillParams array={headerParams} setArray={setHeaderParams} isError={isError} titleKey="header_param" />
        <FillParams array={bodyParams} setArray={setBodyParams} isError={isError} titleKey="body_param" />
        <FillParams array={arrayButtonParams} setArray={setArrayButtonParams} isError={isError} titleKey="url_param" />
        {cards && cards.map((card, index) =>
          <FillCardParams key={index} format={card.header.format} cardIndex={index} isError={isError} />)}
      </div>
      {!isMobile && (
        <Button id="back" text={t('back')} color="white" textType="small" onClick={onBack} image={<ArrowBackIcon />} />
      )}
      <Button type='button' id="next" onClick={onSend} image={loader ? <SmallLoader /> : <></>} disabled={loader}
        color={isMobile ? 'white' : 'orange'} textType={isMobile ? 'regular' : 'small'}
        text={isMobile ? t('next') : t('chats.templates.preview.send_in_chat')} />
    </div>
  );
};

export default ChatTemplateFill;
