import axiosInstance from '../../api/AxiosInstance';
import {
  ChatsRequest,
  ChatType,
  ContactChatsWithMetaData,
  CreateChatResponse,
  ImageUrl,
  ImageUrlRequest, DialogsCountsType,
  MessageModeType,
  MessagePOSTRequest,
  MessageRequest,
  MessagesRequest,
  MessageTemplateRequest,
  ChatAvailabilityRequest,
  ChatAvailability,
  RequestInteractiveTemplate,
} from './ChatTypes';

export const getMessages = async (requestOption: MessagesRequest): Promise<MessageRequest[]> =>
  await axiosInstance.get(`companies/${requestOption.companyId}/messaging/messages/`, {
    params: {
      connection_id: requestOption.connectionId,
      chat_id: requestOption.chatId,
      limit: requestOption.limit,
      until: requestOption.until,
    },
  });

export const getChats = async (requestOption: ChatsRequest): Promise<ChatType[]> =>
  await axiosInstance.get(`companies/${requestOption.companyId}/messaging/chats/`, {
    params: {
      connection_id: requestOption.connectionId,
      offset: 0,
      query: requestOption.query,
    },
  });

export const getChatById = async (
  requestOptions: { connectionId: number, chatId: number, companyId:number }): Promise<ChatType> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/messaging/chats/${requestOptions.connectionId}/${requestOptions.chatId}`);

export const getUrl = async (requestOptions: ImageUrlRequest): Promise<ImageUrl> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/messaging/files/`, {
    params: {
      connection_id: requestOptions.connectionId,
      chat_id: requestOptions.chatId,
      message_id: requestOptions.messageId,
    },
  });

export const uploadFile = async (requestOptions: {companyId:number, file:File}): Promise<{ url: string }> => {
  const formData = new FormData();
  formData.append('file', requestOptions.file);
  return await axiosInstance.post(`companies/${requestOptions.companyId}/messaging/files/`, formData);
};

export const postMessage = async (requestOptions: MessagePOSTRequest ): Promise<MessageModeType> =>
  await axiosInstance.post(`companies/${requestOptions.companyId}/messaging/messages/`, requestOptions);

export const getChatAvailability = async (requestOptions: ChatAvailabilityRequest): Promise<ChatAvailability> => {
  const { companyId, chatId, connectionId } = requestOptions;
  return await axiosInstance.get(`/companies/${companyId}/messaging/chats/${connectionId}/${chatId}/availability`);
};

export const getDialogsCounters = async (companyId: number): Promise<DialogsCountsType[]> =>
  await axiosInstance.get(`/companies/${companyId}/messaging/dialogs/counters/`);

export const postWabaTemplate = 
async (requestOptions: MessageTemplateRequest, currentChat: ChatType): Promise<MessageModeType> => 
  await axiosInstance.post(`companies/${requestOptions.companyId}/messaging/messages/`,
    {
    connection_id: currentChat.connectionId,
    chat_id: currentChat.chatId,
    message_type: 'waba_template',
    waba_template: requestOptions.waba_template
  });

export const mailInteractiveTemplate = async (requestOptions: RequestInteractiveTemplate, currentChat: ChatType) =>
  await axiosInstance.post(`companies/${requestOptions.companyId}/messaging/messages/`, {
    connection_id: currentChat.connectionId,
    chat_id: currentChat.chatId,
    message_type: 'waba_interactive',
    waba_interactive: { ...requestOptions },
  });

export const getListMessageTimestamp = async (requestOptions: {
  connectionId: number;
  chatId: number;
  companyId:number
}): Promise<{ createdAt: string }> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/messaging/last_inbound_message_created_at`, {
    params: {
      connection_id: requestOptions.connectionId,
      chat_id: requestOptions.chatId,
    },
  });

export const getAllCompanyChats = async (requestOptions: { companyId: number; query?: string }): Promise<ChatType[]> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/messaging/chats/all/`, {
    params: {
      company_id: requestOptions.companyId,
      query: requestOptions.query,
    },
  });

export type ChatsWithContactsParamType={
  companyId: number;
  contactId?: number;
  limit?: number;
  cursor?: string;
  searchedValue?: string;
}

export const getChatsWithContacts = async (requestOptions: ChatsWithContactsParamType)
  : Promise<ContactChatsWithMetaData> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/messaging/chats/with_contacts/`, {
    params: {
      contact_id: requestOptions.contactId ?? undefined,
      query: requestOptions.searchedValue,
      cursor: requestOptions.cursor ?? undefined,
      limit: requestOptions.limit ?? undefined,
    },
  });

export const postChat = async (
  requestOption: { companyId: number, connectionId: number, contactId?: number; phone?: string, username?: string }
  ): Promise<CreateChatResponse> =>
  await axiosInstance.post(`companies/${requestOption.companyId}/messaging/chats/`, {
    connection_id: requestOption.connectionId,
    contact_id: requestOption.contactId,
    phone: requestOption.phone,
    user_name: requestOption.username });
