import { useEffect, useState } from 'react';
import './MessageTemplates.scss';
import { useDebounce } from 'use-debounce';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { messageTemplatesSelector } from '../../templatesSelector';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import MessageTemplateCard from '../MessageTemplateCard';
import Search from '../../../../components/Search/Search';
import Button from '../../../../components/Button';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';


function MessageTemplates() {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const templates = useSelector(messageTemplatesSelector);
  const [filteredTemplates, setFilteredTemplates] = useState(templates);
  const [search, setSearch] = useState('');
  const [query] = useDebounce<string>(search, 500);

  useEffect(() => {
    if (query) {
      const filtered = templates.filter((t) => (t.name.toLowerCase().includes(query.toLowerCase())
        || t.text?.toLowerCase().includes(query.toLocaleLowerCase())));
      setFilteredTemplates(filtered);
    } else {
      setFilteredTemplates(templates);
    }
  }, [query, templates]);

  const createTemplate = () => push(`${url}/create`);

  return (
    <div className="messageTemplates">
      <div className="messageTemplates__header">
        <div className="messageTemplates__header_title">
          <h1>{t('templates.title')}</h1>
          <WrapperPermission permission='templatesCreate'>
            <Button type='button' color='orange' textType='regular' text={t('templates.create_templates')}
              onClick={createTemplate} image={<PlusIcon />} />
          </WrapperPermission>
        </div>
        <Search onChangeSearchInput={setSearch} value={search} />
      </div>
      <div className="messageTemplates__content">
        {filteredTemplates.length > 0 && filteredTemplates.map((template) => (
          <MessageTemplateCard key={template.id} template={template} />
          ))}
      </div>
    </div>
  );
}

export default MessageTemplates;
