import { useEffect } from 'react';
import './FilePayloadContent.scss';
import { nanoid } from 'nanoid';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { templateTypeViews } from '../../../../../Chat/Chat/ChatField/WabaTemplateComponent/consts';
import RemoveActionButton from '../RemoveActionButton/RemoveActionButton';
import { ReactComponent as UploadIcon } from '../../../../../../assets/upload.svg';


type Props = {
  payload: { type: string; url: string; };
  error: boolean;
  setUrl: (url: string) => void;
  attachedFile?: File;
  setAttachedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
};

type AcceptedTypes = Record<string, string>;

const acceptedTypes: AcceptedTypes = {
  image: 'image/jpeg, image/png',
  video: 'video/mp4',
  document: '.pdf'
};

const MAX_SIZE = 104857600;

const fileSizeValidation = (file: File) => {
  if (file.size > MAX_SIZE) {
    return {
      code: 'size-to-large',
      message: 'The file size is more than 100 MB',
    };
  }
  return null;
};

const FilePayloadContent: React.FC<Props> = ({ payload, error, setUrl, attachedFile, setAttachedFile }) => {
  const { t } = useTranslation();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: acceptedTypes[payload.type],
    validator: fileSizeValidation
  });

  useEffect(() => {
    setAttachedFile(acceptedFiles[0]);
    setUrl(acceptedFiles[0]?.name || payload.url);
  }, [acceptedFiles]);

  const onRemoveAttachment = () => {
    setUrl('');
    setAttachedFile(undefined);
  };

  const getAttachmentPreview = () => {
    if (attachedFile || payload.url) {
      const TemplatePreviewComponent = templateTypeViews.get(payload.type);

      if (TemplatePreviewComponent) {
        return (
          <TemplatePreviewComponent
            attachedFile={attachedFile}
            key={attachedFile?.name || nanoid()}
            setAttachedFiles={onRemoveAttachment}
            width={220}
            height={170}
            removeTrashIcon
            customTemplateEditPayload={payload.url ? { fileUrl: payload.url, documentName: '' } : undefined}
          />
        );
      }
    }
    return null;
  };

  return (
    <div className="filePayloadContent">
      {attachedFile || payload.url ? (
        <div className="filePayloadContent__attachmentPreview">
          {getAttachmentPreview()}
          <RemoveActionButton onRemoveAction={onRemoveAttachment} />
        </div>
      ) : (
        <>
          <div className={`filePayloadContent__dropzone ${error && 'error'}`} {...getRootProps()}>
            <div className="filePayloadContent__dropzone_text">
              <UploadIcon />
              <p>
                {t(`templates.add_interactive_templates.template_header.header_dropzone.${payload.type}.title`)}
              </p>
              <p>
                {t(`templates.add_interactive_templates.template_header.header_dropzone.${payload.type}.subtitle`)}
              </p>
            </div>
            <input className="filePayloadContent__dropzone_input" type="file" {...getInputProps()} />
          </div>
        </>
      )}
    </div>
  );
};

export default FilePayloadContent;
