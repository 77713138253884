import { useRef, useState } from 'react';
import './TemplateSelector.scss';
import { useTranslation } from 'react-i18next';
import { MessageTemplate } from '../../../../../api/templates/types';
import { useMobile } from '../../../../../components/utils/hooks';
import ToolTip from "../../../../../components/Modals/ToolTip";
import ChatTemplates from "../../ChatTemplates";
import { ReactComponent as TemplateIcon } from '../../../../../assets/template.svg';
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow-down.svg';


type Props = {
  blocked: boolean;
  wabaDialogOnly: boolean;
  setMessageTemplate: React.Dispatch<React.SetStateAction<MessageTemplate | undefined>>;
};

const TemplateSelector = ({ blocked, wabaDialogOnly, setMessageTemplate }: Props) => {
  const { t } = useTranslation();
  const triggerButtonRef = useRef<SVGSVGElement>(null);
  const [openTemplates, setOpenTemplates] = useState(false);
  const isMobile = useMobile();

  const handlerCloseTemplates = () => {
    setOpenTemplates(false);
  };

  const handlerOpenTemplates = () => {
    if (!blocked) setOpenTemplates(true);
  };

  return (
    <>
      <div className={`templateSelector ${blocked && 'blocked'}`} onClick={handlerOpenTemplates}>
        <TemplateIcon className={`templateIcon`} />
        {!isMobile && <span className="smallText middle">{t('chats.chat.templates')}</span>}
        <ArrowIcon className={`arrowIcon ${openTemplates && 'open'}`} ref={triggerButtonRef} />
      </div>
      {openTemplates && (
        <ToolTip refElement={triggerButtonRef} tail={!isMobile} modal onClose={handlerCloseTemplates}>
          <ChatTemplates onClose={handlerCloseTemplates} setMessageTemplate={setMessageTemplate}
            wabaDialogOnly={wabaDialogOnly} />
        </ToolTip>
      )}
    </>
  );
};

export default TemplateSelector;
