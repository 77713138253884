import axiosInstance from "../AxiosInstance";
import { MessageTemplate, WabaTemplate } from "./types";

export const getMessageTemplates = async (requestOptions: { companyId: number }): Promise<MessageTemplate[]> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/messaging/templates/`, {
    params: {
      company_id: requestOptions.companyId,
    },
  });

export const getWabaTemplates = async (
  requestOptions: {companyId: number, connectionId: number }): Promise<WabaTemplate[]> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/messaging/waba_templates/`, {
    params: {
      connection_id: requestOptions.connectionId,
      only_approved: true,
    },
  });
