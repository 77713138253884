import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { removeCustomTemplate } from '../../templatesSlice';
import { MessageTemplate } from '../../../../api/templates/types';
import CardButtons from '../../../../components/CardButtons';
import ActionDialog from '../../../../components/Modals/ActionDialog';


type Props = {
  template: MessageTemplate;
};

function TemplateCardButtons({ template }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const onEdit = () => {
    if (template) {
      push(`${url}/edit/${template.id}`);
    }
  };

  const activeDelete = () => setDeleteOpen(true);

  const deleteTemplate = () => {
    if (currentCompanyId?.params.companyId) {
      dispatch(removeCustomTemplate({
        companyId: Number(currentCompanyId?.params.companyId),
        templateId: template.id,
      }));
    }
  };

  return (<>
    {deleteOpen &&
      <ActionDialog action={t('delete')} cancel={t('cancel')} background='light'
        title={t('templates.delete_confirm.head')} message={t('templates.delete_confirm.warning')}
        onAction={deleteTemplate} setIsOpen={setDeleteOpen} />}
    <CardButtons deletePermission='templatesDelete' editPermission='templatesEdit'
      onDelete={activeDelete} onEdit={onEdit} />
  </>);
}

export default TemplateCardButtons;
