import './ConversationListContainer.scss';
import ConversationList from './ConversationList/ConversationList';
import ConversationHeader from './ConversationHeader/ConversationHeader';


function ConversationListContainer() {
  return (
    <div className='conversationListContainer'>
      <ConversationHeader />
      <ConversationList />
    </div>
  );
};

export default ConversationListContainer;
