import './ConversationList.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { chatsLoaders, chatsWithContactsSelector, searchedChatsWithContacts } from '../../chatsSelector';
import { fetchNextChatsWithContact } from '../../chatsSlice';
import { currentCompanySelector } from '../../companiesSelector';
import { getConversationsList } from "../../utils";
import InfiniteScroll from 'react-infinite-scroll-component';
import Conversation from '../Conversation/Conversation';
import Loader from "../../../../components/Loader/Loader";


const ConversationList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const chatsWithContacts = useSelector(chatsWithContactsSelector);
  const searchedChats = useSelector(searchedChatsWithContacts);
  const loaders = useSelector(chatsLoaders);
  const { id: currentCompanyId } = useSelector(currentCompanySelector);
  const { sortChats } = getConversationsList();

  if (searchedChats) {
    if (searchedChats.length > 0) {
      return (
        <div className='conversationList'>
          {searchedChats.map((searchedChat) => {
            if (chatsWithContacts) {
              const chat = chatsWithContacts.data.find(
                (chatWithContacts) => chatWithContacts.contactId === searchedChat.contactId
              );
              return <Conversation contactChats={chat!} key={chat!.contactId} />;
            }
            return null;
          })}
        </div>
      );
    }
    return (
      <div className='nothingToShow'>
        <p className='smallText'>{t('chats.no_contacts')}</p>
      </div>
    );
  }

  const fetchNextChats = () => {
    dispatch(
      fetchNextChatsWithContact({
        companyId: currentCompanyId,
        limit: 20,
        cursor: chatsWithContacts?.responseMetadata.nextCursor ?? undefined,
      })
    );
  };

  const checkIsMoreChatAvailable = () => {
    if (chatsWithContacts) {
      return chatsWithContacts.responseMetadata.nextCursor.length > 0;
    }
    return false;
  };

  if (loaders.fetchChatsWithContact) {
    return (
      <div className="conversationList">
        <Loader />
      </div>
    );
  }

  return (<> 
    {sortChats && sortChats.length > 0 
      ? <InfiniteScroll
        next={fetchNextChats}
        hasMore={checkIsMoreChatAvailable()}
        loader={null}
        dataLength={sortChats.length}
        scrollableTarget='scrollableContent'
        >
          <div className='conversationList' id='scrollableContent'>
            {sortChats && sortChats.map((chatWithContact) => (
              <Conversation contactChats={chatWithContact} key={chatWithContact.contactId} />
            ))}
          </div>
        </InfiniteScroll>
      : <div className='nothingToShow'>
          <p className='smallText'>{t('chats.no_conversations_message')}</p>
        </div>
    }
  </>);
};

export default ConversationList;
