import { InteractiveHeader, InteractiveType, TemplateTypes } from "../../api/templates/types";


export type PatchMessageTemplateRequestOptions = {
  companyId: number;
  name?: string;
  text?: string;
  fileUrl?: string | null;
  templateId: number;
};

export type CreateMessageTemplateRequestOptions = {
  type: string;
  name: string;
  text: string;
  companyId: number;
  fileUrl?: string;
};

// message template waba interactive types

export type ButtonAction = {
  type: string;
  reply: {
    title: string;
  };
};

export type ListActionPayload = {
  title: string;
  description: string;
};

export type ListActions = {
  button: string;
  sections: Array<{
    rows: ListActionPayload[];
  }>;
};

export type CreateInteractiveTemplateData = {
  type: TemplateTypes;
  name: string;
  waba_interactive: {
    type: InteractiveType;
    header: {
      type: InteractiveHeader;
      text?: string;
      url?: string;
      filename?: string;
    };
    body: string;
    footer?: string;
    action: {
      buttons: ButtonAction[];
      sections: ListActions;
    };
  };
};

export type CreateInteractiveTemplateRequestData = {
  type: TemplateTypes;
  company_id: number;
  name: string;
  waba_interactive: {
    type: InteractiveType;
    body: string;
    header?: {
      type: InteractiveHeader;
      text?: string;
      url?: string;
      filename?: string;
    };
    footer: string | null;
    action: {
      buttons?: ButtonAction[] | null;
      sections?: ListActions | null;
    };
  };
};

/** CreateWabaTemplateRequest */
export const wabaTemplateCategories = ['AUTHENTICATION', 'MARKETING', 'UTILITY'];
export type WabaTemplateCategory = 'MARKETING' | 'AUTHENTICATION' | 'UTILITY';

export type CreateWabaTemplateRequest = {
  company_id: number;
  connection_id: number;
  custom_name: string;
  template_name?: string | null;
  template_locale: string;
  category: WabaTemplateCategory;
  allow_category_change?: boolean;
  components: Array<
    | WabaTemplateComponentHeaderCreateRequest
    | WabaTemplateComponentBody
    | WabaTemplateComponentAuthBody
    | WabaTemplateComponentFooter
    | WabaTemplateComponentAuthFooter
    | WabaTemplateComponentButtons
    | WabaTemplateComponentCarousel>;
  examples: CreateWabaTemplateRequestExamples;
};

export type CreateWabaTemplateRequestExamples = {
  header_text?: string[];
  body_text?: string[];
  button_url_text?: string[] | null;
  header_url?: string | null;
  carousel_cards?: CarouselExample[];
};

export type CarouselExample = {
  body_text?: string[];
  button_url_text?: string[] | null;
  header_url?: string | null;
};

/** WabaTemplateComponentHeaderFormat */
export const wabaTemplateComponentHeaderFormats = ['TEXT', 'IMAGE', 'DOCUMENT', 'VIDEO'];
export type WabaTemplateComponentHeaderFormat = 'TEXT' | 'IMAGE' | 'DOCUMENT' | 'VIDEO';

export type WabaTemplateListButtons =
  | WabaTemplateComponentButtonsButtonUrl
  | WabaTemplateComponentButtonsButtonPhoneNumber
  | WabaTemplateComponentButtonsButtonQuickReply
  | WabaTemplateComponentButtonsButtonOTP
  | WabaTemplateComponentButtonsButtonOTPIn
  | WabaTemplateComponentButtonsButtonPhoneNumberIn;

export type WabaTemplateComponentHeaderCreateRequest = {
  type: 'HEADER';
  format: WabaTemplateComponentHeaderFormat;
  text?: string | null;
};

export type WabaTemplateComponentBody = {
  type: 'BODY';
  text: string;
};

export type WabaTemplateComponentAuthBody = {
  type: 'BODY';
  add_security_recommendation?: boolean | null;
};

export type WabaTemplateComponentFooter = {
  type: 'FOOTER';
  text: string;
};

export type WabaTemplateComponentAuthFooter = {
  type: 'FOOTER';
  code_expiration_minutes?: number | null;
};

export type WabaTemplateComponentCarouselButtons = {
  type: 'BUTTONS';
  buttons: Array<
  | WabaTemplateComponentButtonsButtonUrl
  | WabaTemplateComponentButtonsButtonPhoneNumber
  | WabaTemplateComponentButtonsButtonQuickReply>;
};

export type WabaTemplateComponentCarouselHeader = {
  type: 'HEADER';
  format: Extract<WabaTemplateComponentHeaderFormat, 'IMAGE' | 'VIDEO'>;
};

export type WabaTemplateComponentCarousel = {
  type: 'CAROUSEL';
  cards: Array<{
    components: Array<
      | WabaTemplateComponentCarouselButtons
      | WabaTemplateComponentCarouselHeader
      | WabaTemplateComponentBody>;
  }>;
};

export type WabaTemplateComponentButtons = {
  type: 'BUTTONS';
  buttons: Array<
  | WabaTemplateComponentButtonsButtonUrl
  | WabaTemplateComponentButtonsButtonPhoneNumber
  | WabaTemplateComponentButtonsButtonQuickReply
  | WabaTemplateComponentButtonsButtonOTP>;
};

export type WabaTemplateComponentButtonsButtonUrl = {
  type: 'URL';
  text: string;
  url: string;
};

export type WabaTemplateComponentButtonsButtonPhoneNumber = {
  type: 'PHONE_NUMBER';
  text: string;
  phone_number: string;
};

export type WabaTemplateComponentButtonsButtonQuickReply = {
  type: 'QUICK_REPLY';
  text: string;
};

export type WabaTemplateOtpButtonOtpType = 'COPY_CODE' | 'ONE_TAP';

export type WabaTemplateComponentButtonsButtonOTP = {
  type: 'OTP';
  otp_type: WabaTemplateOtpButtonOtpType;
  text: string;
  autofill_text?: string | null;
  package_name?: string | null;
  signature_hash?: string | null;
};

/** DeleteWabaTemplateRequest */
export type DeleteWabaTemplateRequest = {
  company_id: number;
  connection_id: number;
  template_name: string;
};

/** CreateWabaTemplateResponse */
export type WabaTemplateStatus = 'APPROVED' | 'SUBMITTED' | 'REJECTED' | 'UNKNOWN';

export type WabaTemplateComponentBodyIn = {
  type: 'BODY';
  text: string;
  addSecurityRecommendation?: boolean | null;
};

export type WabaTemplateComponentFooterIn = {
  type: 'FOOTER';
  text: string;
  codeExpirationMinutes?: number | null;
};

export type WabaTemplateComponentButtonsButtonPhoneNumberIn = {
  type: 'PHONE_NUMBER';
  text: string;
  phoneNumber: string;
};

export type WabaTemplateComponentButtonsButtonOTPIn = {
  type: 'OTP';
  otpType: WabaTemplateOtpButtonOtpType;
  text: string;
  autofillText?: string | null;
  packageName?: string | null;
  signatureHash?: string | null;
};

export type WabaTemplateComponentButtonsIn = {
  type: 'BUTTONS';
  buttons: Array<
    | WabaTemplateComponentButtonsButtonUrl
    | WabaTemplateComponentButtonsButtonPhoneNumberIn
    | WabaTemplateComponentButtonsButtonQuickReply
    | WabaTemplateComponentButtonsButtonOTPIn
  >;
};

export type WabaTemplateComponentCarouselButtonsIn = {
  type: 'BUTTONS';
  buttons: Array<
  | WabaTemplateComponentButtonsButtonUrl
  | WabaTemplateComponentButtonsButtonPhoneNumberIn
  | WabaTemplateComponentButtonsButtonQuickReply>;
};

export type WabaTemplateComponentCarouselIn = {
  type: 'CAROUSEL';
  cards: Array<{
    components: Array<
      | WabaTemplateComponentCarouselButtonsIn
      | WabaTemplateComponentCarouselHeader
      | WabaTemplateComponentBody>;
  }>;
};

export type WabaTemplateInfo = {
  name: string;
  locale: string;
  status: WabaTemplateStatus;
  category: WabaTemplateCategory;
  createdAt: string;
  updatedAt: string;
  rejectedReason: string;
  customName?: string | null;
  components: Array<
    | WabaTemplateComponentHeader
    | WabaTemplateComponentBodyIn
    | WabaTemplateComponentFooterIn
    | WabaTemplateComponentButtonsIn
    | WabaTemplateComponentCarouselIn>;
};

export type CreateWabaTemplateResponse = {
  wabaTemplates: WabaTemplateInfo[];
};

export type WabaTemplateComponentHeader = {
  type: 'HEADER',
  format: WabaTemplateComponentHeaderFormat;
  text: string;
};

export type WabaTemplateComponentButtonsOutput = {
  type: 'BUTTONS';
  buttons: Array<
    | WabaTemplateComponentButtonsButtonUrl
    | WabaTemplateComponentButtonsButtonPhoneNumber
    | WabaTemplateComponentButtonsButtonQuickReply
    | WabaTemplateComponentButtonsButtonOTP
  >;
};

export type UpdateWabaTemplateRequest = {
  company_id: number;
  connection_id: number;
  template_name: string;
  template_locale: string;
  custom_name: string;
};
