import './ChatTemplateWaba.scss';
import {
  WabaTemplateBody, WabaTemplateButtons, WabaTemplateCarousel, WabaTemplateFooter, WabaTemplateHeader
} from "../../../../../../api/templates/types";
import { ReactComponent as MessageCheckmark } from '../../../../../../assets/message-checkmark.svg';
import { ReactComponent as Tail } from '../../../../../../assets/message-tail.svg';
import ChatTemplateWabaHeader from "./components/ChatTemplateWabaHeader";
import ChatTemplateWabaBody from "./components/ChatTemplateWabaBody";
import ChatTemplateWabaFooter from "./components/ChatTemplateWabaFooter";
import ChatTemplateWabaButtons from "./components/ChatTemplateWabaButtons";
import ChatTemplateWabaCarousel from './components/ChatTemplateWabaCarousel';


type Props = {
  body: WabaTemplateBody;
  header?: WabaTemplateHeader;
  buttons?: WabaTemplateButtons;
  footer?: WabaTemplateFooter;
  carousel?: WabaTemplateCarousel;
};

const ChatTemplateWaba = ({ body, header, buttons, footer, carousel }:Props) => {

  return (
    <div className="chatTemplateWaba">
      <div className="chatTemplateWaba__content">
        {header && <ChatTemplateWabaHeader header={header}/>}
        <ChatTemplateWabaBody body={body}/>
        {footer && <ChatTemplateWabaFooter footer={footer}/>}
        <div className="messageDate">{'12:15'}<MessageCheckmark /></div>
        <Tail className="chatTemplateWaba__content_tail"/>
      </div>
      {carousel && <ChatTemplateWabaCarousel carousel={carousel} />}
      {buttons && <ChatTemplateWabaButtons buttons={buttons} />}
    </div>
  );
};

export default ChatTemplateWaba;
