import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { messageTemplatesSelector, wabaInteractiveSelector } from "../templatesSelector";
import { MessageTemplate, TemplateTypes } from "../../../api/templates/types";


export function useMessageTemplate(templateIdParam: string, messageTemplateType: TemplateTypes) {
  const templates = messageTemplateType === 'waba_interactive'
    ? useSelector(wabaInteractiveSelector) : useSelector(messageTemplatesSelector);
  const [template, setTemplate] = useState<MessageTemplate>();

  useEffect(() => {
    if (!templateIdParam) return;
    const messageTemplate = templates.find(template => template.id === Number(templateIdParam));
    if (messageTemplate) {
      setTemplate(messageTemplate);
    }
  }, [templates, templateIdParam]);

  return template;
}
